import React from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { MembershipContainer } from './Membership.styles';

import { ReactComponent as EggBadge } from '@/assets/img/icon/egg_badge.svg';
import { ReactComponent as MembershipBadge } from '@/assets/img/icon/membership-badge.svg';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';

function MemberShip(): React.ReactElement {
  const navigate = useNavigate();
  const { data: userMembership, isLoading } = useUserMembership();

  const handleGoToMembershipManagement = () => {
    if (userMembership.membership.membershipGrade === '01') {
      navigate('/membership/entry', { state: { prevPath: location.pathname } });
    } else {
      navigate('membership');
    }
  };

  if (isLoading) {
    return (
      <div>
        <div>loading</div>
      </div>
    );
  }

  if (!userMembership) {
    return null;
  }

  return (
    <MembershipContainer onClick={handleGoToMembershipManagement}>
      {userMembership.membership.membershipGrade === '01' ? (
        <>
          <div className="membership-info">
            <div className="white-club">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.3815 0.450225C8.18179 0.214508 7.81823 0.214509 7.61852 0.450225L6.32102 1.98167C6.19856 2.12621 6.00492 2.18913 5.82088 2.14417L3.87102 1.66786C3.5709 1.59455 3.27678 1.80824 3.25376 2.11633L3.10422 4.11794C3.09011 4.30686 2.97043 4.47158 2.79512 4.54339L0.937679 5.30414C0.651786 5.42124 0.53944 5.767 0.701906 6.02978L1.75744 7.73701C1.85707 7.89815 1.85707 8.10176 1.75744 8.26289L0.701906 9.97013C0.53944 10.2329 0.651786 10.5787 0.937679 10.6958L2.79512 11.4565C2.97043 11.5283 3.09011 11.693 3.10422 11.882L3.25376 13.8836C3.27678 14.1917 3.5709 14.4054 3.87102 14.332L5.82088 13.8557C6.00492 13.8108 6.19856 13.8737 6.32102 14.0182L7.61852 15.5497C7.81823 15.7854 8.18179 15.7854 8.3815 15.5497L9.679 14.0182C9.80147 13.8737 9.9951 13.8108 10.1791 13.8557L12.129 14.332C12.4291 14.4054 12.7232 14.1917 12.7463 13.8836L12.8958 11.882C12.9099 11.693 13.0296 11.5283 13.2049 11.4565L15.0623 10.6958C15.3482 10.5787 15.4606 10.2329 15.2981 9.97013L14.2426 8.26289C14.143 8.10176 14.143 7.89815 14.2426 7.73701L15.2981 6.02978C15.4606 5.767 15.3482 5.42124 15.0623 5.30414L13.2049 4.54339C13.0296 4.47158 12.9099 4.30686 12.8958 4.11794L12.7463 2.11633C12.7232 1.80824 12.4291 1.59455 12.129 1.66786L10.1791 2.14417C9.9951 2.18913 9.80147 2.12621 9.679 1.98167L8.3815 0.450225ZM5.34383 6.52966L5.63013 3.66662L6.75438 3.77905L6.46807 6.64208C6.41265 7.19635 6.84791 7.67731 7.40494 7.67731H7.4351V3.72286H8.56495L8.56495 7.67731H8.59509C9.15213 7.67731 9.58739 7.19636 9.53196 6.64208L9.24566 3.77905L10.3699 3.66662L10.6562 6.52966C10.7781 7.74906 9.82057 8.80716 8.59509 8.80716H8.56495V12.7617H7.4351L7.4351 8.80716H7.40494C6.17946 8.80716 5.22189 7.74906 5.34383 6.52966Z"
                  fill="#14120E"
                />
              </svg>
              WhiteClub
            </div>
            <div className="right-item">
              <div className="btn-upgrade-plan">업그레이드</div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="membership-info">
            <div className="black-club">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.38148 0.450225C8.18178 0.214508 7.81822 0.214509 7.61851 0.450225L6.32101 1.98167C6.19854 2.12621 6.0049 2.18913 5.82087 2.14417L3.87101 1.66786C3.57089 1.59455 3.27676 1.80824 3.25375 2.11633L3.1042 4.11794C3.09009 4.30686 2.97042 4.47158 2.7951 4.54339L0.937664 5.30414C0.651771 5.42124 0.539425 5.767 0.701891 6.02978L1.75743 7.73701C1.85706 7.89815 1.85706 8.10176 1.75743 8.26289L0.701891 9.97013C0.539425 10.2329 0.651771 10.5787 0.937664 10.6958L2.7951 11.4565C2.97042 11.5283 3.09009 11.693 3.1042 11.882L3.25375 13.8836C3.27676 14.1917 3.57089 14.4054 3.87101 14.332L5.82087 13.8557C6.0049 13.8108 6.19854 13.8737 6.32101 14.0182L7.61851 15.5497C7.81822 15.7854 8.18178 15.7854 8.38148 15.5497L9.67899 14.0182C9.80145 13.8737 9.99509 13.8108 10.1791 13.8557L12.129 14.332C12.4291 14.4054 12.7232 14.1917 12.7462 13.8836L12.8958 11.882C12.9099 11.693 13.0296 11.5283 13.2049 11.4565L15.0623 10.6958C15.3482 10.5787 15.4606 10.2329 15.2981 9.97013L14.2426 8.26289C14.1429 8.10176 14.1429 7.89815 14.2426 7.73701L15.2981 6.02978C15.4606 5.767 15.3482 5.42124 15.0623 5.30414L13.2049 4.54339C13.0296 4.47158 12.9099 4.30686 12.8958 4.11794L12.7462 2.11633C12.7232 1.80824 12.4291 1.59455 12.129 1.66786L10.1791 2.14417C9.99509 2.18913 9.80145 2.12621 9.67899 1.98167L8.38148 0.450225ZM5.34382 6.52966L5.63012 3.66662L6.75436 3.77905L6.46806 6.64208C6.41263 7.19635 6.84789 7.67731 7.40493 7.67731H7.43508V3.72286H8.56493L8.56493 7.67731H8.59507C9.15211 7.67731 9.58737 7.19636 9.53194 6.64208L9.24564 3.77905L10.3699 3.66662L10.6562 6.52966C10.7781 7.74906 9.82055 8.80716 8.59507 8.80716H8.56493V12.7617H7.43508L7.43508 8.80716H7.40493C6.17945 8.80716 5.22188 7.74906 5.34382 6.52966Z"
                  fill="white"
                />
              </svg>
              BlackClub
            </div>
            <div className="right-item">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.36038 0.5H4.63962L4.09115 2.14541L3.46163 2.46017L1.74672 1.88853L0.372018 4.63793L1.75 5.74031V6.4669L0.345718 7.30947L1.74672 10.1115L3.40958 9.55719L4.08783 10.0659L4.66148 11.5H7.33852L7.91217 10.0659L8.59042 9.55719L10.2533 10.1115L11.628 7.36207L10.25 6.25969V5.74031L11.628 4.63793L10.2533 1.88853L8.53836 2.46017L7.90885 2.14541L7.36038 0.5ZM4.90885 2.85459L5.36038 1.5H6.63962L7.09115 2.85459L8.46163 3.53983L9.74672 3.11147L10.372 4.36207L9.25 5.25969V6.74031L10.372 7.63793L9.74672 8.88853L8.40958 8.44281L7.08783 9.43412L6.66148 10.5H5.33852L4.91217 9.43412L3.59042 8.44281L2.25328 8.88853L1.65428 7.69053L2.75 7.0331V5.25969L1.62798 4.36207L2.25328 3.11147L3.53836 3.53983L4.90885 2.85459ZM5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6ZM6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>&nbsp;멤버십 관리</div>
            </div>
          </div>
          <div className="black-benefit">
            <div className="black-benefit-contents">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM3.44199 5.49998L2.55811 6.38386L5.00005 8.82581L9.44199 4.38386L8.55811 3.49998L5.00005 7.05804L3.44199 5.49998Z"
                  fill="#E8FF68"
                />
              </svg>
              &nbsp;익스프레스 예약 가능
            </div>
            {/*
            <div className="black-benefit-contents">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM3.44199 5.49998L2.55811 6.38386L5.00005 8.82581L9.44199 4.38386L8.55811 3.49998L5.00005 7.05804L3.44199 5.49998Z"
                  fill="#E8FF68"
                />
              </svg>
              &nbsp;Black Only 다이닝 예약 가능
            </div>*/}
          </div>
        </>
      )}
      {/*
      <div className="content">
        <div className="user-name">
          {userMembership.membership.membershipGrade === '01' ? (
            <>
              <EggBadge />
              <span>EGG 회원</span>
            </>
          ) : (
            <>
              <MembershipBadge width="48" height="48" />
              <span>YOLK 회원</span>
            </>
          )}
        </div>
        <div className="membership-status">{userMembership.membership.membershipGrade === '01' ? '업그레이드' : '멤버십 관리'}</div>
      </div>
      */}
    </MembershipContainer>
  );
}

export default MemberShip;
