import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useMembershipSubscriptionPrices } from './ChooseSubscription.hooks';

import { ChooseSubscriptionContainer, Saving, SubscriptionContent, Title } from '@/components/ChooseSubscription/ChooseSubscription.styles';
import { ControlledRadioGroup } from '@/shared/hook-form/ControlledRadioGroup';
import { AddComma } from '@/shared/libs/functions';

const ChooseSubscription = (): React.ReactElement => {
  const { control } = useFormContext();
  const { data: membershipPriceData, isLoading } = useMembershipSubscriptionPrices();

  if (isLoading) {
    return null;
  }

  const sortMembershipPrices = membershipPriceData.prices.sort((a, b) => Number(a.subscriptionType) - Number(b.subscriptionType));
  const RADIO_CONTENTS = sortMembershipPrices?.map((price) => {
    return {
      id: price.id,
      value: price.subscriptionType,
      render: () => (
        <>
          <SubscriptionContent>
            <h5>{price.subscriptionType === '01' ? '연간구독' : '월간구독'}</h5>
            <div>
              {AddComma(price.actualPrice)}/{price.subscriptionType === '01' ? '년' : '월'} (VAT 포함)
            </div>
          </SubscriptionContent>
          {price.isUse ? price.discountRate !== 0 && <Saving isSave>-{AddComma(price.fixedPrice - price.actualPrice)}원 절약</Saving> : <Saving>오픈 예정</Saving>}
        </>
      ),
      disabled: !price.isUse,
    };
  });

  return (
    <ChooseSubscriptionContainer>
      <Title>
        블랙클럽에 가입하기 위한
        <br /> 마지막 단계입니다.
      </Title>
      {RADIO_CONTENTS && <ControlledRadioGroup className="radio-group" control={control} name="chooseSubscription" items={RADIO_CONTENTS} />}
    </ChooseSubscriptionContainer>
  );
};

export default ChooseSubscription;
