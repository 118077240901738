import styled from '@emotion/styled';

interface IYolkSpinnerContainer {
  isOpenIconLoading: boolean;
}

export const YolkSpinnerContainer = styled.div<IYolkSpinnerContainer>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  /*width: ${(props) => (props.isOpenIconLoading ? '120px' : '80px')};*/
  width: 80px;
`;
