import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const RequestFormContainer = styled.section<React.CSSProperties>`
  > div {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 32px;
    align-self: stretch;
    padding: 0 32px 80px;
    .request-form-box {
      .textarea-wrapper {
        position: relative;

        /* .textarea-resize-icon {
          display: block;
          width: 18px;
          height: 18px;
          padding: 7px;
          box-sizing: content-box;
          position: absolute;
          z-index: 2;
          // border size 만큼 빼준 값
          bottom: 2px;
          right: 2px;
          ////////////////////////
          border-radius: 4px;
          pointer-events: none;
        } */

        .request-textarea {
          resize: none;
          box-sizing: border-box;
          padding: 24px;
          width: 100%;
          height: 208px;
          min-height: 200px;
          border: none;
          /* border: 2px solid ${Colors.white_opacity10}; */
          border-radius: 6px;
          /* font-size: 28px; */
          transition: border-color 0.2s;
          background-color: ${Colors.white_opacity10};
          ${Typography.body_28_s}

          border: none; /* 기본 테두리 제거 */
          outline: none; /* 포커스 시 나타나는 테두리 제거 */

          &::placeholder {
          }

          &:focus {
            /* background: ${Colors.black}; */
            /* border: 2px solid ${Colors.gray9}; */
            outline: none;

            & + .clear-button {
              opacity: 1;
            }
          }

          /* &:placeholder-shown {
            & + .clear-button {
              display: none;
            }
          } */

          /* &:focus {
            border: 2px solid ${Colors.gray9};
            outline: none;
          }

          &:focus-within {
            border: 2px solid ${Colors.gray9};
            outline: none;
            background-color: white;
          }

          &:focus + .textarea-resize-icon {
            line {
              stroke: ${Colors.gray9};
            }
          } */
        }
      }

      .clear-button {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        opacity: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        transition-duration: 1s;
        transition-property: all;

        .icon {
          margin: 28px 24px;
          width: 40px;
          height: 40px;
        }
      }
    }

    .description {
      padding-top: 28px;
      display: flex;
      opacity: 0.6;
      gap: 8px;
      ${Typography.small_3}
      .notice {
      }

      .icon {
        min-width: 24px;
        margin-top: 5px;
        /* height: 26px; */
      }
    }
  }
`;
