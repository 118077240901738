import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';
import { Typography } from '@/shared/styles/typography';

export const TimerContainer = styled.div<React.CSSProperties>`
  display: flex;
  width: 100%;
  padding: 24px 40px;
  align-items: flex-start;
  gap: 28px;
  background: rgba(20, 18, 14, 0.2);

  .time {
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: rgba(232, 255, 104, 0.1);
    ${Typography.small_3};
    color: ${Colors.green1};
    min-width: 92px;

    /* color: ${Colors.red1};
    font-weight: 500;
    ${Typography.title_32_m} */
  }

  .text {
    color: ${Colors.white};
    ${Typography.small_3}
  }
`;

//export const TimerContainer = styled.div<React.CSSProperties>`
//  width: 100%;
//  height: 65px;
//  background-color: #fff1f2;
//  position: sticky;
//  top: 96px;
//  z-index: 10;
//  font-size: 28px;
//  ${Typography.body_26}
//  &.none-header {
//    top: 0;
//  }
//  div {
//    width: 100%;
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -50%);
//    text-align: center;
//    color: ${Colors.red1};
//
//    > b {
//      padding-right: 16px;
//      color: ${Colors.red1};
//      font-weight: 500;
//    }
//  }
//`;

//export const TimerPlaceholder = styled.div<React.CSSProperties>`
//  width: 100%;
//  height: calc(80px);
//`;
