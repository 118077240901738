import React, { useEffect, useId, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import styled from '@emotion/styled';
import throttle from 'lodash/throttle';
import ClipLoader from 'react-spinners/ClipLoader';

import { useHeaderStore } from '../../../../stores/common/useHeaderStore';

import { ReactComponent as InfoIcon } from '@/assets/img/icon/bullet_wrapper.svg';
// import { ReactComponent as InfoIcon } from '@/assets/img/icon/info.svg';
import { ReactComponent as PlusCircle } from '@/assets/img/icon/plusCircle.svg';
import Agreement from '@/components/Agreement/Agreement';
import BookingFooter from '@/components/BookingFooter/BookingFooter';
import Button from '@/components/Button/Button';
import DepositNotice from '@/components/DepositNotice/DepositNotice';
import { CardContent } from '@/components/JoinComponents/JoinComponents.styles';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import PaymentType from '@/components/PaymentType/PaymentType';
import RefundPolicy from '@/components/RefundPolicy/RefundPolicy';
import ReservationInfo from '@/components/ReservationInfo/ReservationInfo';
import Seperator from '@/components/Seperator/Seperator';
import Timer from '@/components/Timer/Timer';
import { useDeleteProvisionalReservation } from '@/hooks/deleteProvisionalReservation.hooks';
import { useFetchCardData } from '@/hooks/useFetchCardData';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { maskingCardNum } from '@/shared/libs/function/masking-card-number';
import { openPaymentModal } from '@/shared/libs/nicepay';
import { Colors, Typography } from '@/shared/styles';
import { IMutationProps } from '@/shared/types/common.types';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import {
  IConfirmPreReservationInfo,
  IConfirmReservationResult,
  IFetchPreReservationResult,
  IResponsePutPrepayment,
  ISubscriptionConfirmReservationResult,
  useBookingStore,
} from '@/stores/useBookingStore';
import { useBookingValidationStore } from '@/stores/useBookingValidationStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const BookingStepPaymentEmotion = styled.div<React.CSSProperties>`
  .placeholder {
    height: 32px;
    width: 100%;
  }
  .cancel-fee-container {
    display: flex;
    padding: 0px 32px 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    /* padding: 0 32px 48px; */
  }
`;

const BookingFooterArea = styled.div<React.CSSProperties>`
  display: flex;
  /* position: sticky; */
  width: 100%;
  padding: 24px;
  align-items: center;
  gap: 24px;
  background: #3b3b39cc;
`;

const BookingContentsArea = styled.section<React.CSSProperties>`
  width: 100%;
  height: calc(100vh - 160px - 96px);
  position: relative;
  overflow: scroll;
`;

const InfoDesc = styled.p`
  padding: 0 32px 80px;
  margin-top: -6px;
  color: ${Colors.gray6};
  display: flex;
  align-items: center;
  flex-direction: row;
  ${Typography.small_3};
  opacity: 0.8;

  > svg {
    margin-right: 16px;
  }
`;

const BookingStepPayment = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const queryToken = new URLSearchParams(search).get('token');
  const query = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { storeUri, mall, reservationInfo, paymentInfo, setReservationInfo } = useBookingStore();
  const { dispatchBookingInfo, ...bookingInfoStore } = useBookingInfoStore();
  const queryReservationType = new URLSearchParams(search).get('reservationType');

  const { agreement, deposit, menuPrice, partySize, detailPartySize, phoneAuthCode, provisionalToken, bookingMenus, phoneAuthToken, additionalInfos, user, request, expiryTime } =
    bookingInfoStore;

  const { mutate: delProvisionalReservationMutate } = useDeleteProvisionalReservation({ token: provisionalToken, storeUri });
  const { validations, dispatchValidations } = useBookingValidationStore();
  const { setMenuModal, setOpenGlobalErrorModal } = useModalStore();
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const { memberId } = useUserProfileStore();
  const checkPaymentKey = useId();

  const { mutateAsync: agreementMutateAsync } = useMutation((mutatedata: IMutationProps) => {
    return eggdiningApi.mutation(mutatedata);
  });

  const { mutate: deleteMutate } = useMutation(() => eggdiningApi.deleteApi(`/eggdining/users/${memberId}/payment-method`), {
    onError: (error) => {
      alert('관리자에게 문의하세요.');
    },
  });
  // 카드 데이터 불러오기
  const { data: paymentCardData, refetch: paymentCardReFetch } = useFetchCardData(true);

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: '예약하기',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: () => {
        if (!queryReservationType) {
          delProvisionalReservationMutate();
        }
        navigate('/main', { replace: true });
      },
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
  }, []);

  const requiredAgreements = Object.entries(agreement)
    .map(([key, value]) => {
      return {
        name: key,
        value: value,
      };
    })
    .filter((item) => {
      // softCheckList(선택) 항목들 집합.
      const softCheckArr = ['EGG_SAVE_CARD_INFO', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT'];
      return !softCheckArr.includes(item.name);
    });

  useEffect(() => {
    if (requiredAgreements.length) {
      if (requiredAgreements.every((item) => item.value === true)) {
        dispatchValidations({ type: 'AGREEMENT_COMPLETE' });
        return;
      }
    }
    dispatchValidations({ type: 'AGREEMENT_INVALID' });
  }, [agreement]);

  useEffect(() => {
    if (queryToken && !provisionalToken) {
      eggdiningApi
        .authGet<IFetchPreReservationResult>(`/eggdining/reservations/pre?token=${queryToken}`)
        .then((res) => {
          if (queryReservationType) {
            dispatchBookingInfo({ type: 'RAFFLE_PRE_RESERVATION', value: (res as any).bookingInfo });
          } else {
            dispatchBookingInfo({ type: 'SET_STATE', value: JSON.parse(sessionStorage.getItem('bookingInfo')) });
          }
          dispatchBookingInfo({ type: 'SET_EXPIRY_TIME', value: res.preReservation.expireDate * 1000 });
          setReservationInfo(res.store);
        })
        .catch((e) => {
          dispatchBookingInfo({ type: 'CLEAR' });
          if (queryReservationType === 'RAFFLE') {
            navigate(`/detail/${query.storeUri}`);
          } else {
            navigate(`/reservation/${query.storeUri}/step/info`);
          }
        });
    }
  }, [queryToken]);

  const sendTermsAgreement = async () => {
    const convertAgreement = Object.entries(agreement).map(([servicePolicyType, serviceAgreement]) => {
      return {
        servicePolicyType,
        agreement: serviceAgreement,
      };
    });

    await agreementMutateAsync({
      operation: 'put',
      url: '/user/termsAgreements',
      data: {
        user: {
          phone: user.phoneNumber.replaceAll('-', ''),
          email: user.email,
        },
        termsAgreements: convertAgreement,
      },
    });
  };

  async function initPayment() {
    try {
      window.Eggapp.setMobileAgent();
    } catch (err) {
      console.log(err);
      console.log('에러가 발생 했습니다.');
    }
    const merchantId = process.env.REACT_APP_NICEPAY_SIMPLE_MERCHANT_ID;
    const merchantKey = process.env.REACT_APP_NICEPAY_SIMPLE_MERCHANT_KEY;
    const convertInfoPeople = detailPartySize.map((count, idx) => {
      return { name: reservationInfo.partyPolicies[idx].name, size: count };
    });
    // 여기다가 넣어주세요
    const sendObject: IConfirmPreReservationInfo = {
      paymentAmount: deposit,
      visitorUserName: user.name,
      visitorPhoneToken: phoneAuthToken,
      visitorPhoneCode: phoneAuthCode,
      email: user.email,
      totalPartySize: partySize,
      reservationType: queryReservationType ? 'RAFFLE' : 'COMMON',
      partyDetail: convertInfoPeople,
      additionalInfo:
        additionalInfos?.map((item) => {
          return {
            name: item.name,
            value: item.value,
          };
        }) || [],
      menuInfo: bookingMenus,
      request,
      tempReservationToken: provisionalToken,
    };
    if (paymentInfo.type === 'BILLING_KEY') {
      setMenuModal({ visible: true, key: checkPaymentKey });
    } else {
      await eggdiningApi
        .put<IResponsePutPrepayment>('eggdining/reservations/prepayment', sendObject)
        .then((res) => {
          openPaymentModal({
            amount: res.paymentAmount,
            orderId: res.merchantUid,
            user: user,
            goodsName: res.paymentName,
            returnUrl: window.location.origin + '/reservation/step/complete',
            callBackUrl: res.paymentCallbackUrl,
            merchantId,
            merchantKey,
            payType: paymentInfo.type,
          });
        })
        .then((res) => {
          sessionStorage.setItem('bookingInfo', JSON.stringify(bookingInfoStore));
          sendTermsAgreement();
        })
        .catch(() => {});
    }
  }

  async function createOrder() {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const convertInfoPeople = detailPartySize.map((count, idx) => {
      return { name: reservationInfo.partyPolicies[idx].name, size: count };
    });

    // 확인 해야 함 타입 필요
    const object: IConfirmPreReservationInfo = {
      paymentAmount: deposit + menuPrice,
      visitorUserName: user.name,
      visitorPhoneToken: phoneAuthToken,
      visitorPhoneCode: phoneAuthCode,
      email: user.email,
      totalPartySize: partySize,
      reservationType: queryReservationType ? 'RAFFLE' : 'COMMON',
      partyDetail: convertInfoPeople,
      additionalInfo:
        additionalInfos?.map((item) => {
          return {
            name: item.name,
            value: item.value,
          };
        }) || [],
      menuInfo: bookingMenus,
      request,
      tempReservationToken: provisionalToken,
    };

    await eggdiningApi.put<IConfirmReservationResult>('eggdining/reservations/confirm', object).then((res) => {
      navigate(`/reservation/step/complete-view/${res.reservationId}`);
    });
    await sendTermsAgreement();
  }

  async function confirmSubscriptionOrder() {
    const convertInfoPeople = detailPartySize.map((count, idx) => {
      return { name: reservationInfo.partyPolicies[idx].name, size: count };
    });
    const sendObject: IConfirmPreReservationInfo = {
      paymentAmount: deposit,
      visitorUserName: user.name,
      visitorPhoneToken: phoneAuthToken,
      visitorPhoneCode: phoneAuthCode,
      email: user.email,
      totalPartySize: partySize,
      partyDetail: convertInfoPeople,
      reservationType: queryReservationType ? 'RAFFLE' : 'COMMON',
      additionalInfo:
        additionalInfos?.map((item) => {
          return {
            name: item.name,
            value: item.value,
          };
        }) || [],
      menuInfo: bookingMenus,
      request,
      tempReservationToken: provisionalToken,
    };
    await eggdiningApi.put<ISubscriptionConfirmReservationResult>('/eggdining/reservations/subscription-confirm', sendObject).then((res) => {
      setIsLoading(false);
      navigate(`/reservation/step/complete?orderId=${res.merchantUid}&status=SUCCESS`);
    });
    await sendTermsAgreement();
  }

  if (!mall || !provisionalToken) {
    return null;
  }

  return (
    <BookingStepPaymentEmotion className="payment-page-container">
      {/* <div className="placeholder"></div> */}
      <BookingContentsArea>
        <Timer />
        <ReservationInfo type="payment" />
        {deposit + menuPrice > 0 ? (
          <div className="cancel-fee-container">
            <MainSubtitle subtitle="취소 수수료 안내" />
            <RefundPolicy policies={reservationInfo.depositRefundPolicies} />
          </div>
        ) : null}
        {deposit + menuPrice > 0 ? (
          <>
            <Seperator />
            <DepositNotice type="payment" />
          </>
        ) : null}
        {deposit + menuPrice > 0 ? (
          <>
            <PaymentType />
          </>
        ) : null}
        <Agreement />
        <InfoDesc>
          <InfoIcon />
          <span>이용금액 결제 시 승인 가맹점 ‘테이블매니저(주)’ 로 표기됩니다.</span>
        </InfoDesc>
      </BookingContentsArea>
      <BookingFooterArea>
        {deposit + menuPrice > 0 ? (
          <BookingFooter text={(deposit + menuPrice).toLocaleString() + '원 결제하기'} active={!validations.agreementError} action={throttle(initPayment, 2000)} />
        ) : (
          <BookingFooter text="예약하기" active={!validations.agreementError} action={throttle(createOrder, 2000)} />
        )}
      </BookingFooterArea>
      {isLoading ? (
        <div className={'loader-container'}>
          <ClipLoader />
        </div>
      ) : null}
      <ModalPortal>
        <Modal.Menu key={checkPaymentKey} modal="menu" isDim={true} isAnimation={true}>
          <section className="header-wrapper">
            <h2 className="title">
              결제수단을 확인해주세요<button onClick={() => deleteMutate()}></button>
            </h2>
          </section>
          <section className="content-wrapper">
            {paymentCardData ? (
              <CardContent visibleCard={true}>
                <section>
                  {paymentCardData.cardCompanyName}
                  <span>{maskingCardNum(paymentCardData.cardNumber)}</span>
                </section>
                <section>
                  <Button
                    onClick={() => navigate(`/membership/payment/change?from=${window.location.pathname + window.location.search}`)}
                    colorLevel="secondary"
                    type="small"
                    isActive={true}
                  >
                    변경
                  </Button>
                </section>
              </CardContent>
            ) : (
              <CardContent onClick={() => navigate(`/membership/payment/add?from=${window.location.pathname + window.location.search}`)}>
                <PlusCircle className="plus-icon" />
                카드 1개 등록 가능
              </CardContent>
            )}
          </section>
          <section className="button-wrapper">
            <Button classKey="premium" onClick={() => confirmSubscriptionOrder()} disabled={paymentCardData ? false : true} colorLevel="primary" type="sticky">
              {(deposit + menuPrice).toLocaleString()}원 결제하기
            </Button>
          </section>
        </Modal.Menu>
      </ModalPortal>
    </BookingStepPaymentEmotion>
  );
};

export default BookingStepPayment;
