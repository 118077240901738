import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '../../typography';

export const MembershipCancelContainer = styled.div<React.CSSProperties>`
  width: 100%;
  padding: 0 32px 80px 32px;
  /*padding: 80px 32px 0;*/

  .cancel-waring {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > h4 {
      margin-top: 12px;
      color: #fff;
      text-align: center;

      /* Headline 2 - SemiBold, 24, 30 */
      font-family: pretendard, sans-serif;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 80px; /* 125% */
    }
  }

  .membership-cancel-component {
    .description {
      ${Typography.title_44}
      margin-bottom: 100px;
    }
    .benefit-img {
      text-align: center;
      margin-bottom: 309px;
    }
    .bottom-section {
      > button {
        width: 100%;
        margin-bottom: 20px;
      }
      > div:first-of-type {
        color: #fff;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;

        /* Body 2 - Regular, 14, 20 */
        font-family: pretendard, sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        /*
        ${Typography.title_36_sb}
        text-align: center;
        */
        margin-bottom: 40px;
      }
      > :last-of-type {
        margin-bottom: 40px;
      }
    }
  }
`;
export const MaintainMembershipContainer = styled.div<React.CSSProperties>`
  display: flex;
  justify-content: center;
  i {
    margin-right: 12px;
  }
`;
