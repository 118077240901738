import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles';
import { Colors } from '@/shared/styles/colors';

export const HeaderContainer = styled.header<React.CSSProperties>`
  width: 100%;
  height: 104px;
  padding: 30px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: ${Colors.gray12};
  /* background-image: inherit; */

  h1 {
    width: 100%;
    max-width: 300px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${Typography.title_32};
    text-align: center;
  }
`;

export const LeftSection = styled.section`
  z-index: 1;
  > svg {
    margin: 24px 0;
    cursor: pointer;
  }
`;
export const RightSection = styled.section`
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 24px;
  > svg {
    cursor: pointer;
    margin: 8px;
    color: white;
  }
`;
