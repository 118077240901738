/* eslint-disable comma-dangle */
import React, { useEffect, useId, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as InputClear } from '@/assets/img/icon/input_clear.svg';
import Limited from '@/assets/img/membership/img_invitation.png';
import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { putApplyJoinMembershipData } from '@/hooks/join.hook';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import {
  CompleteDesc,
  InvitationCode,
  InvitationCodeContainer,
  InvitationContainer,
  LimitedImg,
  SuccessCompleteContainer,
  Title,
  TitleSuper,
} from '@/shared/styles/pages/membership/invitation';
import { IErrorInvitationCode } from '@/shared/types/Invitation.type';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useInvitationCodeStore } from '@/stores/useMembershipJoinStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const Invitation = (): React.ReactElement => {
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const navigate = useNavigate();
  const invitationCodeErrorKey = useId();
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>(null);
  const { memberId } = useUserProfileStore();
  const { data: userMembershipState } = useUserMembership();
  const { refetch: checkUserMembershipGrade } = putApplyJoinMembershipData();
  const { setInvitationCode, invitationCode } = useInvitationCodeStore();
  const { setAlertModal } = useModalStore();
  const location = useLocation();

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: '블랙클럽 멤버십',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });

    const getCode = location.pathname.split('/');
    if (getCode[getCode.length - 1] !== 'invitation') {
      setInvitationCode(getCode[getCode.length - 1]);
    } else if (!invitationCode.length) {
      setInvitationCode('');
    }
  }, []);

  useEffect(() => {
    if (userMembershipState) {
      checkUserMembershipGrade();
    }
  }, [userMembershipState]);

  const { data: validInvitationCodeData, refetch } = useQuery(
    ['INVITATION_CODE'],
    () => eggdiningApi.get(`/eggdining/users/${memberId}/membership/invite-ticket/validate?code=${invitationCode}`),
    {
      onSuccess: () => {
        navigate('/membership/join');
      },
      onError: (error: IErrorInvitationCode) => {
        console.log(error, 'error');
        setCodeErrorMessage(error.response.data.message);
        setAlertModal({ visible: true, key: invitationCodeErrorKey });
      },
      enabled: false,
      retry: 0,
    }
  );

  const onSubmit = () => {
    refetch();
  };

  const acceptOnlyEngNum = (e) => {
    const regExp = /^[A-Za-z0-9]+$/;
    if (!regExp.test(e.key)) {
      e.preventDefault();
    }
    if (e.code === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <>
      <InvitationContainer>
        <div className="blackclub-logo-container">
          <div className="blackclub-emblem">
            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 29 28" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.8815 0.450224C14.6818 0.214508 14.3182 0.214509 14.1185 0.450225L11.4119 3.64485C11.2894 3.78939 11.0958 3.85231 10.9117 3.80735L6.84429 2.81376C6.54417 2.74045 6.25005 2.95414 6.22703 3.26223L5.91508 7.43764C5.90097 7.62656 5.78129 7.79128 5.60598 7.86309L1.73132 9.45004C1.44543 9.56713 1.33308 9.9129 1.49555 10.1757L3.69742 13.737C3.79705 13.8982 3.79705 14.1018 3.69742 14.2629L1.49555 17.8242C1.33308 18.087 1.44543 18.4328 1.73132 18.5499L5.60598 20.1368C5.78129 20.2086 5.90097 20.3733 5.91508 20.5623L6.22703 24.7377C6.25005 25.0458 6.54417 25.2595 6.84429 25.1861L10.9117 24.1926C11.0958 24.1476 11.2894 24.2105 11.4119 24.3551L14.1185 27.5497C14.3182 27.7854 14.6818 27.7854 14.8815 27.5497L17.5881 24.3551C17.7106 24.2105 17.9042 24.1476 18.0882 24.1926L22.1557 25.1861C22.4558 25.2595 22.7499 25.0458 22.773 24.7377L23.0849 20.5623C23.099 20.3733 23.2187 20.2086 23.394 20.1368L27.2687 18.5499C27.5546 18.4328 27.6669 18.087 27.5044 17.8242L25.3026 14.2629C25.2029 14.1018 25.2029 13.8982 25.3026 13.737L27.5044 10.1757C27.6669 9.9129 27.5546 9.56713 27.2687 9.45004L23.394 7.86309C23.2187 7.79128 23.099 7.62656 23.0849 7.43764L22.773 3.26223C22.7499 2.95414 22.4558 2.74045 22.1557 2.81376L18.0882 3.80735C17.9042 3.85231 17.7106 3.78939 17.5881 3.64485L14.8815 0.450224ZM9.85168 11.4269L10.3527 6.41663L12.3201 6.61337L11.8191 11.6237C11.7221 12.5937 12.4838 13.4353 13.4586 13.4353H13.5114V6.51505H15.4886V13.4353H15.5414C16.5162 13.4353 17.2779 12.5937 17.1809 11.6237L16.6799 6.61337L18.6473 6.41663L19.1483 11.4269C19.3617 13.5609 17.686 15.4126 15.5414 15.4126H15.4886V22.333H13.5114V15.4126H13.4586C11.314 15.4126 9.63828 13.5609 9.85168 11.4269Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="complete-desc">
            <h4>
              블랙클럽 멤버십 가입 신청이
              <br />
              완료되었습니다.
            </h4>
            <p>
              신청자가 많아 선정에 시간이 걸릴 수 있습니다.
              <br />
              선정된 분들께는 문자메시지로 초대코드를 보내드립니다.
            </p>
          </div>
        </div>
        <div className="g-line">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="6" viewBox="0 0 375 6" fill="none">
            <path opacity="0.1" d="M0 6V0H375V6H0Z" fill="white" width="100%" height="6" />
          </svg>
        </div>

        {/*
        <SuccessCompleteContainer>
          <TitleSuper>신청이 완료되었습니다.</TitleSuper>
          <Title>
            모실 수 있게 되면, 문자로
            <br />
            초대코드를 보내드려요.
          </Title>
          <LimitedImg src={Limited} alt="limited 우편 이미지" />
          <CompleteDesc>
            좋은 서비스를 위해 한정인원으로 운영 중입니다.
            <br />
            많은 분을 모시지 못하는 점, 양해 부탁드려요.
          </CompleteDesc>
        </SuccessCompleteContainer>
        */}
        <InvitationCodeContainer>
          <h3>이미 초대코드를 받으셨나요?</h3>
          <InvitationCode>
            <Input
              value={invitationCode}
              onKeyPress={acceptOnlyEngNum}
              onChange={(e) => {
                if (invitationCode.length < 10 || e.target.value.length < invitationCode.length) {
                  setInvitationCode(e.target.value);
                }
              }}
              type="text"
              maxLength={10}
              className="code-input"
              placeholder="초대코드를 입력해주세요."
              clearicon={<InputClear onClick={() => setInvitationCode('')} />}
            />
            <Button
              classKey="code-submit-btn"
              onClick={onSubmit}
              defaultType="button"
              colorLevel="secondary"
              type="large"
              disabled={!/^[A-Za-z0-9]*$/.test(invitationCode) || invitationCode?.length !== 10}
            >
              초대코드 입력
            </Button>
          </InvitationCode>
        </InvitationCodeContainer>
      </InvitationContainer>
      <ModalPortal>
        <Modal.Alert key={invitationCodeErrorKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">{codeErrorMessage}</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: invitationCodeErrorKey })}>닫기</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Invitation;
