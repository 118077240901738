import React from 'react';

import styled from '@emotion/styled';

import { Variables } from '@/shared/styles';

export const LayoutContainer = styled.main<React.CSSProperties>`
  @supports (-webkit-touch-callout: none) {
    height: 100vh !important;
  }
  min-height: 100vh;
  ${Variables.maxWidth};
  ${Variables.fullWidth};
  position: relative;
  overflow-y: scroll;
  box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 0px 0px 0px rgba(0, 0, 0, 0.1);
  height: 100vh;
  height: -webkit-fill-available;
  height: fill-available;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #3b3b39;
  /*padding-bottom: calc(60px + env(safe-area-inset-bottom));*/
`;

export const PullRefreshContainer = styled.div<React.CSSProperties>`
  display: inline-block;
  position: relative;
  width: 55px;
`;
