import styled from '@emotion/styled';

import { IToastModalProps } from './Modal.type';

import { Animations, Typography, Variables } from '@/shared/styles';
import { Colors } from '@/shared/styles/colors';

export const ModalContainer = styled.div<IToastModalProps>`
  .alert-express-img {
    width: 100%;
  }
  z-index: 10;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: ${(props) => (props.toastModal ? 'auto' : '100%')};

  & > .modal-dim {
    width: 100%;
    height: 100%;
    background: rgba(39, 42, 45, 0.4);
  }

  & > .modal-content {
    background: var(--gray-700, #3b3b39) !important;
    color: #fff !important;
  }

  &.event-alert {
    z-index: 20;

    /* 임시 */
    img {
      width: 576px;
      height: 768px;
    }

    &.animation {
      animation-duration: 0.3s;
      animation-name: ${Animations.fadeIn};
    }
    & > .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 560px;
      width: calc(100% - 160px);
      /* min-height: 340px; */
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /* background: ${Colors.white}; */
      /* backdrop-filter: blur(56.3792px); */
      border-radius: 20px;

      .content-wrapper {
        .title {
          padding-top: 50px;
          padding-inline: 40px;
          padding-bottom: 30px;
          ${Typography.title_32_sb};
          color: ${Colors.black};
        }

        .desc {
          padding-bottom: 50px;
          padding-inline: 40px;
          ${Typography.body_26};
          color: ${Colors.gray8};

          .desc-in-padding {
            padding-top: 28px;
          }

          .check-img-wrapper {
            margin-right: calc(-10px + 12px);
          }
        }
      }

      .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          width: 100%;
          padding-block: 29px;
          ${Typography.title_32_sb};

          &:nth-of-type(2) {
            border-left: 2px solid ${Colors.gray2};
            color: ${Colors.yellow3};
          }

          &.red {
            color: ${Colors.red2};
          }
          &.gray {
            color: ${Colors.gray8};
          }
        }
      }
    }
    .event-modal-bottom {
      display: flex;
      justify-content: space-between;
      > div {
        ${Typography.body_28_sb}
        color: ${Colors.white};
      }
      margin-top: 22px;
    }
  }

  // alert
  &.alert {
    z-index: 20;

    &.animation {
      animation-duration: 0.3s;
      animation-name: ${Animations.fadeIn};
    }
    & > .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      transform: translate(-50%, -50%);
      width: 590px;
      /* width: calc(100% - 160px);       */
      /* max-width: 560px; */
      /* min-height: 340px; */
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: rgba(59, 59, 57, 0.8);

      /* effect-overlays */
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15), 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
      /* backdrop-filter: blur(50px); */

      /* position: absolute;
      top: 50%;
      left: 50%;
      max-width: 560px;
      width: calc(100% - 160px);
      min-height: 340px;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: ${Colors.white};
      backdrop-filter: blur(56.3792px);
      border-radius: 20px; */

      .content-wrapper {
        display: flex;
        min-height: 192px;
        padding: 48px 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        color: ${Colors.white};
        .title {
          ${Typography.title_32};
          text-align: center;
          /*color: ${Colors.black};*/
          color: #fff;
        }

        .desc {
          /* padding-bottom: 50px; */
          /* padding-inline: 40px; */
          ${Typography.small_3}
          color: #fff;
          opacity: 0.8;
          text-align: center;

          .desc-in-padding {
            padding-top: 28px;
          }

          .check-img-wrapper {
            margin-right: calc(-10px + 12px);
          }
        }
      }

      .lots-of-content-wrapper {
        .title {
          padding-block: 27px;
          border-bottom: 2px solid ${Colors.gray2};
        }

        .desc {
          overflow: auto;
          padding-top: 50px;
        }

        .refund-table {
          padding-top: 48px;
          h4 {
            ${Typography.body_28_sb};
          }

          dl {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 28px 0 16px;
            border-bottom: 2px solid #fff;
            gap: 12px;

            dt,
            dd {
              ${Typography.body_26};
            }

            dt {
              width: 50%;
              /*color: ${Colors.gray6};*/
              color: #fff;
              opacity: 0.9;
            }

            dd {
              /*color: ${Colors.gray8};*/
              color: #fff;
              opacity: 0.8;
            }
          }

          .total-wrapper {
            padding-top: 16px;
            ${Typography.body_26_sb};
            display: flex;
            align-items: center;
            justify-content: space-between;

            em {
              font-style: normal;
            }

            span {
              line-height: 42px;
              /*color: ${Colors.gray8};*/
              color: #fff;
            }
          }
        }
      }

      .button-wrapper {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        /* display: flex;
        align-items: center;
        justify-content: center;
        border-top: 2px solid ${Colors.gray2}; */

        button {
          font-size: 28px !important;
          display: flex;
          padding: 26px 0px;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          border-top: 2px solid rgba(255, 255, 255, 0.1);
          ${Typography.title_32}
          color: ${Colors.green1};

          &:nth-of-type(2) {
            border-left: 2px solid rgba(255, 255, 255, 0.1);
          }

          /* width: 100%;
          padding-block: 29px;
          ${Typography.title_32_sb}; */

          &.white {
            color: ${Colors.white};
          }

          /*
           &:nth-of-type(1) {
            border-right: 1px solid ${Colors.gray2};
            color: ${Colors.white};
          }
          

          &.red {
            color: ${Colors.red2};
          }
          &.yellow {
            color: ${Colors.yellow3};
          }
          &.gray {
            color: ${Colors.gray8};
          }
          &.green {
            color: var(--green-300, #e8ff68);
          } */
        }
      }
    }
  }
  &.normal {
    &.animation {
      animation-duration: 0.3s;
      animation-name: ${Animations.fadeIn};
    }
    & > .modal-content {
    }
  }

  // menu
  &.menu {
    & > .modal-content {
      .header-wrapper {
        padding: 56px 32px 0;
        display: flex;
        justify-content: space-between;

        .title {
          font-weight: 600;
          font-size: 36px;
          line-height: 46px;
          color: ${Colors.white};
          opacity: 0.8;

          > b {
            color: ${Colors.blue};
          }
        }

        svg {
          margin: 10px;
        }
      }

      .content-wrapper {
        padding-block: 40px;
        padding-inline: 32px;
        ${Typography.body_28};
        color: ${Colors.gray8};
        overflow: auto;

        img {
          max-width: 100%;
          padding-top: 30px;
          display: inline-block;
        }
      }

      .button-wrapper {
        display: flex;
        align-items: center;
        position: sticky;
        bottom: 0;

        button {
          ${Typography.title_32_sb};
        }

        button:first-of-type {
          flex-shrink: unset;
          border-color: ${Colors.gray2};
        }
        button:nth-of-type(2) {
          max-width: 60%;
          border-top: 2px solid ${Colors.gray2};
        }
      }

      &.animation {
        animation-name: ${Animations.slideUp};
        animation-duration: 0.3s;
      }
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      width: 100%;
      height: auto;
      overflow: auto;
      max-height: calc(100% - 44px);
      display: flex;
      flex-direction: column;
      background: white;
      ${Variables.maxWidth};
      background: ${Colors.white};
      border-radius: 32px 32px 0px 0px;
    }
  }

  // toast
  &.toast {
    position: relative;
    /* max-width: 720px; */
    margin: 0 auto;
    width: 100%;
    width: calc(100% - 32px - 32px);

    &.animation {
      animation-name: ${Animations.fadeIn};
      animation-duration: 0.5s;
    }
    &.animation-fadeout {
      animation-name: ${Animations.fadeOut};
      animation-duration: 0.5s;
    }
    & > .modal-content {
      padding: 30px 32px;
      background: #272a2d;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      text-align: center;
      ${Typography.title_32_m};
      div {
        color: ${Colors.white};
      }
    }
  }
  &.setting {
    &.animation {
      animation-name: ${Animations.slideUp};
      animation-duration: 0.3s;
    }
    & > .modal-content {
    }
  }
  &.check {
    &.animation {
      animation-name: ${Animations.slideUp};
      animation-duration: 0.3s;
    }
    & > .modal-content {
    }
  }
`;
