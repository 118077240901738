import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const ExpressInfoContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding: 48px 32px;
  gap: 16px;
  & > h1 {
    ${Typography.title_36_sb};
    color: ${Colors.white};
    padding-bottom: 12px;
  }
  & > h2 {
    ${Typography.body_28};
    color: ${Colors.white};
    margin-top: 28px;
    & > span {
      ${Typography.body_28_ss}
    }
  }
  & > ul {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 4px;
    & > li {
      ${Typography.small_3};
      color: ${Colors.gray0};
      list-style: disc;
      & > span {
        color: ${Colors.green1};
      }
    }
  }
`;

export const ExpressOpeningHoursContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background: #e8ff681a;
  border: #e8ff68 1px solid;
  border-radius: 6px;
  gap: 16px;
  & > h1 {
    text-align: center;
    ${Typography.title_32_s};
    color: ${Colors.white};
    &.opened {
      ${Typography.title_32_s}
    }
  }
  & > h3 {
    text-align: center;
    ${Typography.small_3};
    color: ${Colors.white};
    padding-bottom: 12px;
  }
  & > span {
    text-align: center;
    ${Typography.small_3};
    color: ${Colors.white};
    opacity: 80%;
    & > svg {
      margin-bottom: -3px;
      margin-right: 10px;
    }
  }
`;
