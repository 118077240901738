import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EgglogoMain } from '@/assets/img/egg_logo_main.svg';
//import { SignInPageContainer } from '@/shared/styles/pages/auth/signin';
import { LocalStorage } from '@/shared/configs/storage';
import { MainSplashContainer } from '@/shared/styles/pages/mainSplash';
import { useHeaderStore } from '@/stores/common/useHeaderStore';

const InitialPage = (): React.ReactElement => {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigation = useNavigate();
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  useEffect(() => {
    setHeaderState({
      title: null,
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
    setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    setTimeout(() => {
      //navigation('/auth/signin', { replace: true });
      const memberId = LocalStorage.getItem('memberId');
      const token = LocalStorage.getItem('jwtToken');

      if (memberId && token) {
        navigation('/main');
      } else {
        navigation('/auth/signin', { replace: true });
      }
    }, 1000);
  }, [isLoaded]);
  return (
    <>
      {/*<MainSplashContainer className={!isLoaded ? '' : 'fadeout-bg'}>*/}
      <MainSplashContainer>
        <h2 className="logo-text">DiningClub</h2>
        {/*<EgglogoMain className="egglogomain" />*/}
      </MainSplashContainer>
    </>
  );
};

export default InitialPage;
