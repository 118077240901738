import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const VisitScheduledContainer = styled.div<React.CSSProperties>`
  display: flex;
  padding: 40px 32px;
  flex-direction: column;
  gap: 48px;
`;

export const VisitScheduledItemContainer = styled.div<React.CSSProperties>`
  display: flex;
  gap: 16px;
  .img-wrapper {
    max-width: 162px;
    min-height: 162px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    img {
      max-width: 100%;
      min-height: 100%;
    }
    span {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 42px;
      left: 0;
      bottom: 0;
      ${Typography.small_1};
      color: ${Colors.white};
      background: ${Colors.yellow1};
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 26px;
    h3 {
      color: #fff;
      text-overflow: ellipsis;

      /* Title 2 - SemiBold, 18, 24 */
      font-family: pretendard, sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
      /*
      ${Typography.subtitle_m};
      font-weight: 600;
      color: ${Colors.gray9};
      */
    }
    span {
      ${Typography.body_26}
      /*color: ${Colors.gray6};*/
      color:#fff;
      opacity: 0.5;
    }

    .reserve-diff {
      align-self: flex-start;
      opacity: 1;
      border-radius: 6px;
      background: rgba(232, 255, 104, 0.1);
      padding: 10px 12px;
      /*max-width : 60px;*/
      justify-content: center;
      gap: 4px;
      color: var(--green-300, #e8ff68);
      text-align: center;

      /* Body 3 - Regular, 12, 18 */

      font-family: pretendard, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
  }
`;
