import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { useMembershipSubscriptionPrices } from '../ChooseSubscription/ChooseSubscription.hooks';
import { useUserMembership } from './ManageMembership.hooks';

import { ReactComponent as Arrow } from '@/assets/img/icon/arrowRightGray.svg';
import { ReactComponent as BenefitExpress } from '@/assets/img/icon/benefit_express.svg';
import { ReactComponent as MembershipBadge } from '@/assets/img/icon/membership-badge.svg';
import Button from '@/components/Button/Button';
import { ManageMembershipContainer } from '@/components/ManageMembership/ManageMembership.styles';
import { useFetchCardData } from '@/hooks/useFetchCardData';
import { AddComma } from '@/shared/libs/functions';

const ManageMembership = (): React.ReactElement => {
  const { data: userMembership, isLoading: userMembershipLoading } = useUserMembership();
  const navigate = useNavigate();
  const { data: membershipPriceData, isLoading: membershipPriceLoading } = useMembershipSubscriptionPrices();
  const { data: paymentCardData, isLoading: fetchCardLoading } = useFetchCardData(true);

  const viewMoreBenefitDetail = () => {
    navigate('/membership/entry?member=2', { state: { prevPath: location.pathname } });
  };

  const findMembership = useMemo(() => {
    if (userMembership && membershipPriceData) {
      return membershipPriceData.prices.find((member) => member.subscriptionType === userMembership.membership.subscriptionType);
    }
  }, [membershipPriceData, userMembership]);

  if (userMembershipLoading || fetchCardLoading || membershipPriceLoading) {
    return null;
  }

  return (
    <ManageMembershipContainer isYolkMember={userMembership.membership.membershipGrade === '02'}>
      <div className="membership-header">
        {userMembership.membership.membershipGrade === '02' ? (
          <div className="user-current-memberhip-info">
            <div className="user-profile-emblem-yolk">
              <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 29 28" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.8815 0.450224C14.6818 0.214508 14.3182 0.214509 14.1185 0.450225L11.4119 3.64485C11.2894 3.78939 11.0958 3.85231 10.9117 3.80735L6.84429 2.81376C6.54417 2.74045 6.25005 2.95414 6.22703 3.26223L5.91508 7.43764C5.90097 7.62656 5.78129 7.79128 5.60598 7.86309L1.73132 9.45004C1.44543 9.56713 1.33308 9.9129 1.49555 10.1757L3.69742 13.737C3.79705 13.8982 3.79705 14.1018 3.69742 14.2629L1.49555 17.8242C1.33308 18.087 1.44543 18.4328 1.73132 18.5499L5.60598 20.1368C5.78129 20.2086 5.90097 20.3733 5.91508 20.5623L6.22703 24.7377C6.25005 25.0458 6.54417 25.2595 6.84429 25.1861L10.9117 24.1926C11.0958 24.1476 11.2894 24.2105 11.4119 24.3551L14.1185 27.5497C14.3182 27.7854 14.6818 27.7854 14.8815 27.5497L17.5881 24.3551C17.7106 24.2105 17.9042 24.1476 18.0882 24.1926L22.1557 25.1861C22.4558 25.2595 22.7499 25.0458 22.773 24.7377L23.0849 20.5623C23.099 20.3733 23.2187 20.2086 23.394 20.1368L27.2687 18.5499C27.5546 18.4328 27.6669 18.087 27.5044 17.8242L25.3026 14.2629C25.2029 14.1018 25.2029 13.8982 25.3026 13.737L27.5044 10.1757C27.6669 9.9129 27.5546 9.56713 27.2687 9.45004L23.394 7.86309C23.2187 7.79128 23.099 7.62656 23.0849 7.43764L22.773 3.26223C22.7499 2.95414 22.4558 2.74045 22.1557 2.81376L18.0882 3.80735C17.9042 3.85231 17.7106 3.78939 17.5881 3.64485L14.8815 0.450224ZM9.85168 11.4269L10.3527 6.41663L12.3201 6.61337L11.8191 11.6237C11.7221 12.5937 12.4838 13.4353 13.4586 13.4353H13.5114V6.51505H15.4886V13.4353H15.5414C16.5162 13.4353 17.2779 12.5937 17.1809 11.6237L16.6799 6.61337L18.6473 6.41663L19.1483 11.4269C19.3617 13.5609 17.686 15.4126 15.5414 15.4126H15.4886V22.333H13.5114V15.4126H13.4586C11.314 15.4126 9.63828 13.5609 9.85168 11.4269Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="user-current-membership-title">BlackClub</div>
          </div>
        ) : (
          <div className="white-club-top-container">
            <div className="user-current-memberhip-info">
              <div className="user-profile-emblem-normal">
                <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 29 28" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.8815 0.450224C14.6818 0.214508 14.3182 0.214509 14.1185 0.450225L11.4119 3.64485C11.2894 3.78939 11.0958 3.85231 10.9117 3.80735L6.84429 2.81376C6.54417 2.74045 6.25005 2.95414 6.22703 3.26223L5.91508 7.43764C5.90097 7.62656 5.78129 7.79128 5.60598 7.86309L1.73132 9.45004C1.44543 9.56713 1.33308 9.9129 1.49555 10.1757L3.69742 13.737C3.79705 13.8982 3.79705 14.1018 3.69742 14.2629L1.49555 17.8242C1.33308 18.087 1.44543 18.4328 1.73132 18.5499L5.60598 20.1368C5.78129 20.2086 5.90097 20.3733 5.91508 20.5623L6.22703 24.7377C6.25005 25.0458 6.54417 25.2595 6.84429 25.1861L10.9117 24.1926C11.0958 24.1476 11.2894 24.2105 11.4119 24.3551L14.1185 27.5497C14.3182 27.7854 14.6818 27.7854 14.8815 27.5497L17.5881 24.3551C17.7106 24.2105 17.9042 24.1476 18.0882 24.1926L22.1557 25.1861C22.4558 25.2595 22.7499 25.0458 22.773 24.7377L23.0849 20.5623C23.099 20.3733 23.2187 20.2086 23.394 20.1368L27.2687 18.5499C27.5546 18.4328 27.6669 18.087 27.5044 17.8242L25.3026 14.2629C25.2029 14.1018 25.2029 13.8982 25.3026 13.737L27.5044 10.1757C27.6669 9.9129 27.5546 9.56713 27.2687 9.45004L23.394 7.86309C23.2187 7.79128 23.099 7.62656 23.0849 7.43764L22.773 3.26223C22.7499 2.95414 22.4558 2.74045 22.1557 2.81376L18.0882 3.80735C17.9042 3.85231 17.7106 3.78939 17.5881 3.64485L14.8815 0.450224ZM9.85168 11.4269L10.3527 6.41663L12.3201 6.61337L11.8191 11.6237C11.7221 12.5937 12.4838 13.4353 13.4586 13.4353H13.5114V6.51505H15.4886V13.4353H15.5414C16.5162 13.4353 17.2779 12.5937 17.1809 11.6237L16.6799 6.61337L18.6473 6.41663L19.1483 11.4269C19.3617 13.5609 17.686 15.4126 15.5414 15.4126H15.4886V22.333H13.5114V15.4126H13.4586C11.314 15.4126 9.63828 13.5609 9.85168 11.4269Z"
                    fill="#14120E"
                  />
                </svg>
              </div>
              <div className="user-current-membership-title">WhiteClub</div>
              <div className="user-current-membership-contents">다이닝클럽을 더 폭넓게 즐겨보세요.</div>
              <div className="black-benefit2">
                <div className="black-benefit-year-price">
                  <div>
                    <h4>연간 구독</h4>
                    <br />
                    <small>330,000/년 (VAT 포함)</small>
                  </div>
                </div>
                <div className="black-benefit-right-item">
                  <div className="year-price-benefit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
                      <g clip-path="url(#clip0_696_5979)">
                        <path
                          d="M1.35356 5.44135L0.646454 6.14846L6.00001 11.502L11.3536 6.14846L10.6465 5.44135L6.5 9.58781V0.794922L5.5 0.794922V9.58779L1.35356 5.44135Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_696_5979">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    &nbsp;66,000원 절약
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/*
        <div className="badge">
          <MembershipBadge width="160" height="160" />
        </div>
        <div className="comment">
          <div className="title">YOLK 멤버십</div>
          <div className="description">{userMembership.membership.membershipGrade === '02' ? '에그 백퍼센트 활용중!' : '에그 백퍼센트 활용해보세요!'}</div>
        </div>
        */}
      <div className="depth-line"></div>
      <div className="manage-membership-title-container">
        <div className="manage-membership-title">혜택 안내</div>
        <div className="manage-membership-title-right-item" onClick={viewMoreBenefitDetail}>
          <div>
            자세히 보기 &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 12 12" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.55887 0.557617L3.67499 1.4415L8.23305 5.99956L3.67499 10.5576L4.55887 11.4415L10.0008 5.99956L4.55887 0.557617Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="black-benefit">
        <div className="black-club">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.38148 0.450225C8.18178 0.214508 7.81822 0.214509 7.61851 0.450225L6.32101 1.98167C6.19854 2.12621 6.0049 2.18913 5.82087 2.14417L3.87101 1.66786C3.57089 1.59455 3.27676 1.80824 3.25375 2.11633L3.1042 4.11794C3.09009 4.30686 2.97042 4.47158 2.7951 4.54339L0.937664 5.30414C0.651771 5.42124 0.539425 5.767 0.701891 6.02978L1.75743 7.73701C1.85706 7.89815 1.85706 8.10176 1.75743 8.26289L0.701891 9.97013C0.539425 10.2329 0.651771 10.5787 0.937664 10.6958L2.7951 11.4565C2.97042 11.5283 3.09009 11.693 3.1042 11.882L3.25375 13.8836C3.27676 14.1917 3.57089 14.4054 3.87101 14.332L5.82087 13.8557C6.0049 13.8108 6.19854 13.8737 6.32101 14.0182L7.61851 15.5497C7.81822 15.7854 8.18178 15.7854 8.38148 15.5497L9.67899 14.0182C9.80145 13.8737 9.99509 13.8108 10.1791 13.8557L12.129 14.332C12.4291 14.4054 12.7232 14.1917 12.7462 13.8836L12.8958 11.882C12.9099 11.693 13.0296 11.5283 13.2049 11.4565L15.0623 10.6958C15.3482 10.5787 15.4606 10.2329 15.2981 9.97013L14.2426 8.26289C14.1429 8.10176 14.1429 7.89815 14.2426 7.73701L15.2981 6.02978C15.4606 5.767 15.3482 5.42124 15.0623 5.30414L13.2049 4.54339C13.0296 4.47158 12.9099 4.30686 12.8958 4.11794L12.7462 2.11633C12.7232 1.80824 12.4291 1.59455 12.129 1.66786L10.1791 2.14417C9.99509 2.18913 9.80145 2.12621 9.67899 1.98167L8.38148 0.450225ZM5.34382 6.52966L5.63012 3.66662L6.75436 3.77905L6.46806 6.64208C6.41263 7.19635 6.84789 7.67731 7.40493 7.67731H7.43508V3.72286H8.56493L8.56493 7.67731H8.59507C9.15211 7.67731 9.58737 7.19636 9.53194 6.64208L9.24564 3.77905L10.3699 3.66662L10.6562 6.52966C10.7781 7.74906 9.82055 8.80716 8.59507 8.80716H8.56493V12.7617H7.43508L7.43508 8.80716H7.40493C6.17945 8.80716 5.22188 7.74906 5.34382 6.52966Z"
              fill="white"
            />
          </svg>
          BlackClub
        </div>
        <div className="black-benefit-contents">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM3.44199 5.49998L2.55811 6.38386L5.00005 8.82581L9.44199 4.38386L8.55811 3.49998L5.00005 7.05804L3.44199 5.49998Z"
              fill="#E8FF68"
            />
          </svg>
          &nbsp;익스프레스 예약 가능
        </div>
        {/*
        <div className="black-benefit-contents">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM3.44199 5.49998L2.55811 6.38386L5.00005 8.82581L9.44199 4.38386L8.55811 3.49998L5.00005 7.05804L3.44199 5.49998Z"
              fill="#E8FF68"
            />
          </svg>
          &nbsp;Black Only 다이닝 예약 가능
        </div>
        */}
      </div>
      {userMembership.membership.membershipGrade === '02' ? (
        <div>
          <div className="manage-membership-title-container">
            <div className="manage-membership-title">
              멤버십 관리 <span>이용중</span>
            </div>
          </div>
          <div className="membership-pay-info-container">
            <div className="membership-pay-info">
              <span className="payment-titl">결제수단</span>
              <span className="payment-content">{paymentCardData ? paymentCardData.cardCompanyName : '카드 정보 없음'}</span>
            </div>
            <div className="membership-pay-info">
              <span className="payment-titl">구독료</span>
              <span className="payment-content">
                {findMembership.subscriptionType === '01' ? '연' : '월'} {AddComma(findMembership.actualPrice)}(VAT 포함)
              </span>
            </div>
            <div className="membership-pay-info">
              <span className="payment-titl">최근결제</span>
              <span className="payment-content">{dayjs(userMembership.membership.paymentDate).format('YYYY년 MM월 DD일')}</span>
            </div>
            <div className="membership-pay-info">
              <span className="payment-titl">다음결제</span>
              <span className="payment-content">
                {userMembership.membership.cancelDate
                  ? `해지(${dayjs(userMembership.membership.applyEndDate).format('YYYY년 MM월 DD일')} 까지 이용가능)`
                  : dayjs(userMembership.membership.applyEndDate).format('YYYY년 MM월 DD일')}
              </span>
            </div>
          </div>
          <Button
            colorLevel="secondary"
            type="sticky"
            classKey="auth-button"
            onClick={() => {
              navigate(`/membership/payment/change?from=${window.location.pathname + window.location.search}`);
            }}
          >
            결제수단 변경
          </Button>
          <div className="depth-line-small"></div>
          <Link to="/membership/cancel">
            <div className="membership-cancel-container">
              <div className="membership-cancel-title">멤버십 해지</div>
              <div className="membership-cancel-title-right-item">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.55887 0.557617L3.67499 1.4415L8.23305 5.99956L3.67499 10.5576L4.55887 11.4415L10.0008 5.99956L4.55887 0.557617Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ) : null}
      {/*
      <div className="benefit">
        <div>
          <div>혜택 안내</div>
          <p onClick={viewMoreBenefitDetail}>
            자세히보기 <Arrow />
          </p>
        </div>
        <div className="event-description">
          <div className="description">
            <div>욜크회원 전용 우선예약</div>
            <div>익스프레스 예약 제공</div>
          </div>
          <div className="express-img">
            <BenefitExpress />
          </div>
        </div>
      </div>
      */}
      {/*<div className="manage-membership-info">
          <div className="title">
            <div>멤버십 관리</div>
            <p>이용중</p>
          </div>
          <div className="membership-info">
            <div className="payment-info">
              <div>결제 수단</div>
              <div>구독료</div>
              <div>최근결제</div>
              <div>다음결제</div>
            </div>
            <div className="payment-data">
              <div>{paymentCardData ? paymentCardData.cardCompanyName : '카드 정보 없음'}</div>
              <div>
                {findMembership.subscriptionType === '01' ? '연' : '월'} {AddComma(findMembership.actualPrice)}(VAT 포함)
              </div>
              <div>{dayjs(userMembership.membership.paymentDate).format('YYYY년 MM월 DD일')}</div>
              <div>
                {userMembership.membership.cancelDate
                  ? `해지(${dayjs(userMembership.membership.applyEndDate).format('YYYY년 MM월 DD일')} 까지 이용가능)`
                  : dayjs(userMembership.membership.applyEndDate).format('YYYY년 MM월 DD일')}
              </div>
            </div>
          </div>
        </div>*/}
      {/*
      {!userMembership.membership.cancelDate && userMembership.membership.membershipGrade === '02' ? (
        <div className="rescission-membership">
          <Link to="/membership/cancel">
            <button>멤버십 해지</button>
          </Link>
        </div>
      ) : null}
      */}
    </ManageMembershipContainer>
  );
};

export default ManageMembership;
