import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const VisitCancelAndNoShowContainer = styled.div<React.CSSProperties>`
  display: flex;
  padding: 40px 32px;
  flex-direction: column;
  gap: 48px;
`;
export const VisitCancelAndNoShowItemContainer = styled.div<React.CSSProperties>`
  display: flex;
  gap: 16px;
  & > div:first-of-type {
    max-width: 162px;
    min-height: 162px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    img {
      max-width: 100%;
      min-height: 100%;
    }
    & > span {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      ${Typography.body_28_m};
      color: ${Colors.white};
      background: ${Colors.gray9_opacity40};
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 26px;
    & > h3 {
      /*
      ${Typography.subtitle_m};
      font-weight: 600;
      color: ${Colors.gray9};
      */
      color: #fff;
      text-overflow: ellipsis;

      /* Title 2 - SemiBold, 18, 24 */
      font-family: pretendard, sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
    }
    & > span {
      ${Typography.body_26}
      /*color: ${Colors.gray6};*/
      color:#fff;
      opacity: 0.5;
    }
    .reserve-stts {
      align-self: flex-start;
      opacity: 1;
      border-radius: 6px;
      background: rgba(255, 124, 117, 0.1);
      padding: 10px 12px;
      /*max-width : 60px;*/
      justify-content: center;
      gap: 4px;
      color: var(--red-400, #ff7c75);
      text-align: center;

      /* Body 3 - Regular, 12, 18 */

      font-family: pretendard, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
  }
`;
