import React from 'react';

import { AddressContainer, AddressMapInfoContainer } from './Address.styles';

import { ReactComponent as Phone } from '@/assets/img/icon/phone.svg';
import KakaoMap from '@/components/KakaoMap/KakaoMap';
import { callService } from '@/hooks/callService';
import { useBookingStore } from '@/stores/useBookingStore';

const Address = ({ store }): React.ReactElement => {
  const { storeOpenTimes } = useBookingStore().mall;
  // 영업시간과 휴무일 정보를 분리
  const openTimeInfo = storeOpenTimes
    .filter((item) => item.type !== 'CLOSE')
    .map(
      (item) =>
        `${item.target} ${Math.floor(item.start / 60)
          .toString()
          .padStart(2, '0')}:${(item.start % 60).toString().padStart(2, '0')}~${Math.floor(item.end / 60)
          .toString()
          .padStart(2, '0')}:${(item.end % 60).toString().padStart(2, '0')}${item.etc ? ` (${item.etc})` : ''}`
    )
    .join(', ');

  const closedInfo = storeOpenTimes
    .filter((item) => item.type === 'CLOSE')
    .map((item) => `${item.target} 휴무`)
    .join(', ');
  return (
    <AddressContainer>
      <h1>매장 정보</h1>
      <KakaoMap lat={store.latitude} lng={store.longitude} address={store.address} />
      <AddressMapInfoContainer>
        <div className="info-item">
          <span>주소</span>
          <span>
            {store.address}
            <br></br>({store.pastAddress})
          </span>
        </div>
        <div className="info-item">
          <span>전화</span>
          <span>{store.phone}</span>
        </div>
        <div className="info-item">
          <span>영업시간</span>
          <span>{openTimeInfo}</span>
        </div>
        {closedInfo ? (
          <>
            <div className="info-item">
              <span>휴무일</span>
              <span></span>
            </div>
          </>
        ) : null}
      </AddressMapInfoContainer>
    </AddressContainer>
  );
};

export default Address;
