import React from 'react';
import { useLocation } from 'react-router-dom';

import PolicyModal from '../PolicyModal/PolicyModal';
import PolicyModalPortal from '../PolicyModalPortal/PolicyModalPortal';
import { FooterVisibleExceptions } from './Footer.constants';
import { FooterContainer, SalesRelay, TermsService, TmInfo } from './Footer.styles';

import { useFetchPolicies } from '@/hooks/useFetchPolicies';
import { useFooterStore } from '@/stores/common/useFooterStore';

const requiredPolicies = ['EGG_CONFIRM_BIZ_INFO', 'SERVICE', 'PRIVACY'];

const Footer = (): React.ReactElement => {
  const { pathname } = useLocation();
  const isFooterVisible = useFooterStore((store) => store.isFooterVisible);
  const { servicePolicies, loading, openServiceTerm, currentPolicy } = useFetchPolicies({ url: '/service/policies', requiredPolicies: requiredPolicies });

  if (FooterVisibleExceptions.includes(pathname)) {
    return <></>;
  }
  if (!isFooterVisible) {
    return <></>;
  }

  const openPolicyModal = (type) => {
    if (type === 'EGG_CONFIRM_BIZ_INFO') {
      const findExternalLink = servicePolicies.find((item) => item.type === type)?.content.KO.contentUrl;
      window.location.href = findExternalLink;
      return null;
    }
    window.history.pushState(null, null, pathname);
    openServiceTerm(type);
  };

  if (loading) {
    return;
  }

  return (
    <>
      <FooterContainer>
        <TmInfo>
          <h5>테이블매니저(주)</h5>
          <div>
            대표이사 : 안병준
            <br />
            주소 : 서울시 강남구 논현로153길 53, 602호(신사동)
            <br />
            통신판매 신고 : 2024-서울강남-02339호
            <br />
            고객센터 : help@tablemanager.team
          </div>
        </TmInfo>
        <TermsService>
          <ul>
            {servicePolicies?.map((policy) => (
              <li
                key={policy.id}
                onClick={() => {
                  openPolicyModal(policy.type);
                  return null;
                }}
              >
                {policy.content.KO.name}
              </li>
            ))}
          </ul>
        </TermsService>
        <SalesRelay>테이블매니저(주)는 통신판매 중개자이며 통신판매의 당사자가 아닙니다. 관련하여 상품거래 정보 및 거래에 대한 책임을 지지 않습니다.</SalesRelay>
      </FooterContainer>
      <PolicyModalPortal>
        <PolicyModal openServiceTerm={openServiceTerm} url={currentPolicy} />
      </PolicyModalPortal>
    </>
  );
};

export default Footer;
