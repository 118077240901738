import React from 'react';
import Countdown from 'react-countdown';

import dayjs from 'dayjs';

import { ReactComponent as Bell } from '@/assets/img/icon/bell.svg';
import { ReactComponent as BulletWrapper } from '@/assets/img/icon/bullet_wrapper.svg';
import CountdownRenderer from '@/components/CountdownRenderer/CountdownRenderer';
import { ExpressInfoContainer, ExpressOpeningHoursContainer } from '@/components/ExpressInfo/ExpressInfo.styles';
import Seperator from '@/components/Seperator/Seperator';
import { useBookingStore } from '@/stores/useBookingStore';

const ExpressInfo = (): React.ReactElement => {
  const { storeMembershipPeriod, storeName } = useBookingStore().mall;
  if (storeMembershipPeriod.storeWillBeOpendList.length === 0 || !storeMembershipPeriod.current.openDateTime) {
    return null;
  }

  return (
    <>
      <ExpressInfoContainer>
        <h1>예약 안내</h1>
        <ExpressOpeningHoursContainer>
          {storeMembershipPeriod.current.status === 'SERVE' ? <h1 className="opened">익스프레스 예약 오픈</h1> : <h1 className="ready-to-open">익스프레스 예약 오픈까지</h1>}
          {storeMembershipPeriod.current.status === 'SERVE' ? null : <Countdown date={storeMembershipPeriod.current.openDateTime.toDate()} renderer={CountdownRenderer} />}
          <h3>
            블랙클럽 회원은 {storeMembershipPeriod.current.reserveOpenDateTime.format('M월 D일')}~{storeMembershipPeriod.current.reserveCloseDateTime.format('M월 D일')} 예약에{' '}
            <br />
            먼저 참여할 수 있어요.
          </h3>
          {storeMembershipPeriod.current.commonOpenTime ? (
            <span>
              <BulletWrapper></BulletWrapper>에그회원은 {storeMembershipPeriod.current.commonOpenTime.format('M월 D일')}부터 예약 가능해요.
            </span>
          ) : (
            <span>
              <BulletWrapper></BulletWrapper>에그회원 예약을 받지 않는 매장이에요.
            </span>
          )}
        </ExpressOpeningHoursContainer>
        <h2>
          {storeName}
          <span>에서는</span>
        </h2>
        <ul>
          {Boolean(storeMembershipPeriod?.storeWillBeOpendList.find((item) => item.membershipGrade === '02')) && (
            <li>
              <span>{dayjs(storeMembershipPeriod?.storeWillBeOpendList.find((item) => item.membershipGrade === '02').openStartDate).format('M월 DD일 HH시')}</span>에 블랙클럽
              회원만 접수할 수 있는 익스프레스 예약을 열어요.
            </li>
          )}
          {Boolean(storeMembershipPeriod?.storeWillBeOpendList.find((item) => item.membershipGrade === '01')) && (
            <li>
              <span>{dayjs(storeMembershipPeriod?.storeWillBeOpendList.find((item) => item.membershipGrade === '01').openStartDate).format('M월 DD일 HH시')}</span>에 일반 회원이
              접수할 수 있는 일반 예약을 열어요.
            </li>
          )}
        </ul>
      </ExpressInfoContainer>
      <Seperator />
    </>
  );
};

export default ExpressInfo;
