import { useNavigate } from 'react-router-dom';

import { AdditionalContent, AdditionalProfileContainer } from './AdditionalProfile.styles';

import { ReactComponent as Mail } from '@/assets/img/icon/mail.svg';
import { ReactComponent as History } from '@/assets/img/icon/reservHistory.svg';
import { ReactComponent as Arrow } from '@/assets/img/icon/rightArrow.svg';
import { mailService } from '@/shared/libs/function/mailService';

function AdditionalProfile() {
  const navigate = useNavigate();

  return (
    <AdditionalProfileContainer>
      <div className="contour-line"></div>
      <div
        className="additional-profile-content"
        onClick={() => {
          navigate('/reservation/history');
        }}
      >
        <AdditionalContent>예약 내역</AdditionalContent>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.55887 0.558105L3.67499 1.44199L8.23305 6.00005L3.67499 10.5581L4.55887 11.442L10.0008 6.00005L4.55887 0.558105Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="additional-profile-content" onClick={() => mailService('egg@tablemanager.team')}>
        <AdditionalContent>1:1 문의</AdditionalContent>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.55887 0.558105L3.67499 1.44199L8.23305 6.00005L3.67499 10.5581L4.55887 11.442L10.0008 6.00005L4.55887 0.558105Z"
            fill="white"
          />
        </svg>
      </div>
    </AdditionalProfileContainer>
  );
}

export default AdditionalProfile;
