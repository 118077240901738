import {} from './BookingInfo.constants';
import {} from './BookingInfo.type';

import React, { useEffect, useId, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import { BigSizeBookingInfoTimer, BookingInfoContainer, SmallSizeBookingInfoTimer, TimerInterserction } from './BookingInfo.styles';

import { useInterserction } from '@/hooks/useIntersection';
import { useModalStore } from '@/stores/common/useModalStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useBookingStore } from '@/stores/useBookingStore';

const BookingInfo = ({ queryReservationType }): React.ReactElement => {
  const navigate = useNavigate();
  const query = useParams();
  const { setAlertModal } = useModalStore();
  const { expiryTime, dateTime, partySize, dispatchBookingInfo } = useBookingInfoStore();
  const [mall, storeUri, setStoreUri, reservationInfo] = useBookingStore((store) => [store.mall, store.storeUri, store.setStoreUri, store.reservationInfo]);
  const [now, setNow] = useState(dayjs());
  const [target, setTarget] = useState<Element | null>(null);
  const reservationTimeOut = useId();
  const [bigTimer, setBigTimer] = useState(true);

  const isIntersecting = useInterserction({ target });

  const timeOutReservation = () => {
    setAlertModal({ visible: true, key: reservationTimeOut });
  };

  useEffect(() => {
    const i = setInterval(() => setNow(dayjs()), 1000);
    return () => clearInterval(i);
  }, []);

  useEffect(() => {
    if (now.isAfter(expiryTime)) {
      timeOutReservation();
    }
  }, [now]);

  //useEffect(() => {
  //  if (isIntersecting) {
  //    setBigTimer(true);
  //  } else if (!isIntersecting) {
  //    setBigTimer(false);
  //  }
  //}, [isIntersecting]);

  useEffect(() => {
    if (query.storeUri) {
      setStoreUri(query.storeUri);
    }
  }, [query.storeUri]);

  const diffTimeFormat = (() => {
    if (!expiryTime) {
      return '00:00';
    }
    const diffSecond = expiryTime.diff(now, 'second');
    if (diffSecond <= 0) {
      return '00:00';
    }
    return `${Math.floor(diffSecond / 60)
      .toString()
      .padStart(2, '0')}:${(diffSecond % 60).toString().padStart(2, '0')}`;
  })();

  if (!mall) {
    return null;
  }

  return (
    <>
      {bigTimer ? (
        <BigSizeBookingInfoTimer>
          {diffTimeFormat ? <div className="time">{diffTimeFormat}</div> : null}
          <div className="text">원활한 예약을 위해 좌석을 홀딩합니다. 해당 시간 내에 예약을 완료해 주세요.</div>
        </BigSizeBookingInfoTimer>
      ) : (
        <SmallSizeBookingInfoTimer>
          {diffTimeFormat ? <div className="time">{diffTimeFormat}</div> : null}
          <div className="text">이내에 예약을 완료해주세요.</div>
        </SmallSizeBookingInfoTimer>
      )}
      <TimerInterserction ref={setTarget}></TimerInterserction>
      <BookingInfoContainer>
        <div className="mall-name">{reservationInfo.name}</div>
        <div className="info-text">
          {dateTime?.format('YYYY.M.D (dd) · LT · ')} {partySize}명
        </div>
      </BookingInfoContainer>
      <ModalPortal>
        <Modal.Alert key={reservationTimeOut} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <section className="desc">
              <div>예약유효시간이 만료되었습니다.</div>
            </section>
          </section>
          <section className="button-wrapper">
            <button
              onClick={() => {
                if (queryReservationType === 'RAFFLE') {
                  navigate(`/detail/${storeUri}`);
                } else {
                  navigate(`/reservation/${storeUri}/step/info`);
                }
                dispatchBookingInfo({ type: 'CLEAR' });
              }}
            >
              닫기
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default BookingInfo;
