import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalStorage } from '@/shared/configs/storage';
import { useModalStore } from '@/stores/common/useModalStore';

const useConfirmLoginBeforeMoving = () => {
  const navigate = useNavigate();
  const { setOpenGlobalConfirmModal } = useModalStore();
  const confirmLoginBeforeMoving = (moveUrl: string) => {
    if (LocalStorage.getItem('loginType') === 'none') {
      setOpenGlobalConfirmModal({
        visible: true,
        title: '로그인 해야 사용할 수 있는 기능입니다. 로그인하시겠어요?',
        message: '',
        closeText: '닫기',
        okayText: '로그인',
        callback: () => {
          navigate('/auth/signin');
        },
      });
    } else {
      navigate(moveUrl);
    }
  };
  return { confirmLoginBeforeMoving };
};

export { useConfirmLoginBeforeMoving };
