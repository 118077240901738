import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const ImageCarouselContainer = styled.div<React.CSSProperties>`
  /* carousel custom */
  & > .carousel-root {
    & > .carousel-slider {
      & > .control-dots {
        margin: 16px 0;
        & > li {
          box-shadow: none;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export const ImageMainCarouselItem = styled.div<React.CSSProperties>`
  width: 100%;
  padding: 32px 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  background: ${(props) => (props.background ? `url(${props.background}) no-repeat center center/cover` : 'transparent')};
  height: 580px;
  border-radius: 6px;
  & > h2 {
    ${Typography.title_36_sb};
    color: ${(props) => (props.background && props.background === Colors.gray9 ? Colors.white : Colors.gray9)};
    text-align: left;
  }
  & > span {
    ${Typography.body_26};
    color: ${Colors.gray6};
  }
`;

export const ImageDetailCarouselItem = styled.div<React.CSSProperties>`
  width: 100%;
  background: ${(props) => (props.background ? props.background : 'transparent')};
`;
