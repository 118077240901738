/* eslint-disable comma-dangle */
import { Fragment, useEffect, useId } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Banner from '@/assets/img/membership/membership_banner.jpg';
import Step01 from '@/assets/img/membership/step01.png';
import Step02 from '@/assets/img/membership/step02.png';
import Step03 from '@/assets/img/membership/step03.png';
import Button from '@/components/Button/Button';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import {
  AgonizeList,
  BorderLine,
  ButtonWrapper,
  CheckSection,
  Desc,
  DescWrapper,
  DiscountPricesInfo,
  HowToApply,
  HowToUse,
  InvitationDesc,
  LargeTitle,
  MembershipHeader,
  Notice,
  NoticeListWrap,
  SectionTitle,
  SmallTitle,
} from '@/shared/styles/pages/membership/entry';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useMembershipInfoStore } from '@/stores/useMembershipInfoStore';
import { useSaveEntryPathStore } from '@/stores/useMembershipJoinStore';

const Entry = () => {
  const yolkInvitationCodeAlert = useId();
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const { setEntryPath } = useSaveEntryPathStore();
  const { membershipGrade } = useMembershipInfoStore();
  const { setAlertModal } = useModalStore();

  const navigate = useNavigate();
  const { state: propsState, state: goToMypage } = useLocation();
  const [searchParams] = useSearchParams();
  useUserMembership();

  useEffect(() => {
    setIsNavigationVisible(false);
    setHeaderState({
      title: '블랙클럽 멤버십',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => (goToMypage ? navigate('/mypage') : navigate(-1)),
      handleOnShare: null,
    });
  }, []);

  useEffect(() => {
    if (propsState?.prevPath) {
      const { prevPath } = propsState;
      setEntryPath(prevPath);
    }
  }, []);

  const applyJoinMembership = () => {
    navigate('/membership/invitation');
  };

  return (
    <Fragment>
      <MembershipHeader>
        <h4>Join the club</h4>
        <p>오직 다이닝클럽에서만 누릴 수 있는 혜택을 즐겨보세요.</p>
      </MembershipHeader>
      <DescWrapper>
        <div className="black-club">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 16 16" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.38148 0.450225C8.18178 0.214508 7.81822 0.214509 7.61851 0.450225L6.32101 1.98167C6.19854 2.12621 6.0049 2.18913 5.82087 2.14417L3.87101 1.66786C3.57089 1.59455 3.27676 1.80824 3.25375 2.11633L3.1042 4.11794C3.09009 4.30686 2.97042 4.47158 2.7951 4.54339L0.937664 5.30414C0.651771 5.42124 0.539425 5.767 0.701891 6.02978L1.75743 7.73701C1.85706 7.89815 1.85706 8.10176 1.75743 8.26289L0.701891 9.97013C0.539425 10.2329 0.651771 10.5787 0.937664 10.6958L2.7951 11.4565C2.97042 11.5283 3.09009 11.693 3.1042 11.882L3.25375 13.8836C3.27676 14.1917 3.57089 14.4054 3.87101 14.332L5.82087 13.8557C6.0049 13.8108 6.19854 13.8737 6.32101 14.0182L7.61851 15.5497C7.81822 15.7854 8.18178 15.7854 8.38148 15.5497L9.67899 14.0182C9.80145 13.8737 9.99509 13.8108 10.1791 13.8557L12.129 14.332C12.4291 14.4054 12.7232 14.1917 12.7462 13.8836L12.8958 11.882C12.9099 11.693 13.0296 11.5283 13.2049 11.4565L15.0623 10.6958C15.3482 10.5787 15.4606 10.2329 15.2981 9.97013L14.2426 8.26289C14.1429 8.10176 14.1429 7.89815 14.2426 7.73701L15.2981 6.02978C15.4606 5.767 15.3482 5.42124 15.0623 5.30414L13.2049 4.54339C13.0296 4.47158 12.9099 4.30686 12.8958 4.11794L12.7462 2.11633C12.7232 1.80824 12.4291 1.59455 12.129 1.66786L10.1791 2.14417C9.99509 2.18913 9.80145 2.12621 9.67899 1.98167L8.38148 0.450225ZM5.34382 6.52966L5.63012 3.66662L6.75436 3.77905L6.46806 6.64208C6.41263 7.19635 6.84789 7.67731 7.40493 7.67731H7.43508V3.72286H8.56493L8.56493 7.67731H8.59507C9.15211 7.67731 9.58737 7.19636 9.53194 6.64208L9.24564 3.77905L10.3699 3.66662L10.6562 6.52966C10.7781 7.74906 9.82055 8.80716 8.59507 8.80716H8.56493V12.7617H7.43508L7.43508 8.80716H7.40493C6.17945 8.80716 5.22188 7.74906 5.34382 6.52966Z"
              fill="white"
            />
          </svg>
          BlackClub
        </div>
        <div className="benefit-price-container">
          <s>정가 월 33,000원</s> <span className="sale-rate">약 80% 할인</span>
        </div>
        <h4>
          월 6,900원으로 제공되는
          <br />
          익스프레스 예약{' '}
        </h4>
        <p>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12 12" fill="none">
            <g clip-path="url(#clip0_708_2305)">
              <path
                d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 10.9821C3.24911 10.9821 1.01786 8.75089 1.01786 6C1.01786 3.24911 3.24911 1.01786 6 1.01786C8.75089 1.01786 10.9821 3.24911 10.9821 6C10.9821 8.75089 8.75089 10.9821 6 10.9821Z"
                fill="white"
              />
              <path
                d="M5.35693 3.64286C5.35693 3.81335 5.42466 3.97687 5.54522 4.09743C5.66578 4.21799 5.82929 4.28571 5.99979 4.28571C6.17029 4.28571 6.3338 4.21799 6.45436 4.09743C6.57492 3.97687 6.64265 3.81335 6.64265 3.64286C6.64265 3.47236 6.57492 3.30885 6.45436 3.18829C6.3338 3.06773 6.17029 3 5.99979 3C5.82929 3 5.66578 3.06773 5.54522 3.18829C5.42466 3.30885 5.35693 3.47236 5.35693 3.64286ZM6.32122 5.14286H5.67836C5.61943 5.14286 5.57122 5.19107 5.57122 5.25V8.89286C5.57122 8.95179 5.61943 9 5.67836 9H6.32122C6.38015 9 6.42836 8.95179 6.42836 8.89286V5.25C6.42836 5.19107 6.38015 5.14286 6.32122 5.14286Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_708_2305">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>{' '}
          VAT(부가가치세) 포함
        </p>
        <div className="introduce-express">
          <p className="introduct-express-title">익스프레스 예약이란?</p>
          <p className="introduct-express-body">
            블랙클럽 회원에게 먼저 예약기간을 열어주는
            <br />
            <b>우선예약</b> 혜택입니다.
          </p>
        </div>
      </DescWrapper>
      <HowToUse>
        <div className="exclusive-title">Exclusive</div>
        <h4>
          초대코드를 가진 한정된 분들만
          <br />
          모실 수 있습니다.
        </h4>
        <p>
          가입 버튼을 눌러 초대코드 신청을 해주시면,
          <br />
          선정 과정을 거쳐 연락드립니다.
        </p>
        <div className="b-line">
          <svg xmlns="http://www.w3.org/2000/svg" width="303" height="1" viewBox="0 0 303 1" fill="none">
            <path opacity="0.1" d="M0 1V0H303V1H0Z" fill="white" />
          </svg>
        </div>
        <ol>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 15 14" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM4.51565 6.41664L3.48446 7.44784L6.33339 10.2968L11.5157 5.11451L10.4845 4.08331L6.33339 8.23438L4.51565 6.41664Z"
                fill="#E8FF68"
              />
            </svg>
            &nbsp;빨리 신청할수록 선정 확률이 높아집니다.
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 15 14" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM4.51565 6.41664L3.48446 7.44784L6.33339 10.2968L11.5157 5.11451L10.4845 4.08331L6.33339 8.23438L4.51565 6.41664Z"
                fill="#E8FF68"
              />
            </svg>
            &nbsp;노쇼나 취소 내역이 많으면 거절될 수 있습니다.
          </li>
        </ol>
      </HowToUse>
      <HowToApply>
        <h4>유의사항 안내</h4>
        <NoticeListWrap>
          <li>익스프레스 예약의 양도 및 매매는 불가합니다. 적발 즉시 멤버십이 정지됩니다.(환불 불가)</li>
          <li>매장당 한 달에 최대 2번까지 예약할 수 있습니다. 예약을 취소해도 횟수는 복원되지 않습니다.</li>
          <li>예약의 양도 및 매매를 방지하는 차원에서 같은 날 동시간대 중복 예약은 불가합니다.</li>
          <li>멤버십 해지 시 결제 금액은 환불되지 않습니다.</li>
          <li>특정 레스토랑만을 위한 가입은 추천하지 않습니다.</li>
          <li>위약금 적용기간 내 예약 취소 3회 초과 시 멤버십 자동 해지됩니다.</li>
          <li>위약금 적용기간 외 예약 취소 10회 초과 시 멤버십 자동 해지됩니다.</li>
          <li>노쇼의 경우 조건 없이 멤버십 해지됩니다.</li>
          <li>위 규정 위반 시 재가입은 불가합니다.</li>
          <li>예약 시간 기준 30분 이상 지각 혹은 위반시에는 향후 예약에 대해 불이익을 받을 수 있습니다.</li>
        </NoticeListWrap>
      </HowToApply>
      {/*
      <MembershipHeader>
        <img src={Banner} alt="욜크 멤버십 배너" />
        <p>
          욜크 멤버십은 소수를 위한 특별 멤버십으로 <br />
          <b>한정된 인원을 선정</b>하여 운영됩니다.
        </p>
      </MembershipHeader>
      <DescWrapper>
        <SectionTitle>오픈 혜택</SectionTitle>
        <LargeTitle>
          <DiscountPricesInfo>
            정가 월 33,000원<em>약 80% 할인</em>
          </DiscountPricesInfo>
          월 6,900원으로 제공되는 <br />
          익스프레스 예약
          <span>*VAT(부가가치세) 포함</span>
        </LargeTitle>
        <BorderLine>
          <Desc>
            <h3>
              <b>익스프레스 예약</b>이란?
            </h3>
            <p>
              욜크회원에게 먼저 예약기간을 열어 주는 <b>우선예약 혜택이에요.</b>
            </p>
          </Desc>
        </BorderLine>
      </DescWrapper>
      <HowToUse>
        <SmallTitle>익스프레스 예약, 어떻게 사용할까요?</SmallTitle>
        <ol>
          <li>
            <img src={Step01} alt="예약 방법 1 - 이 표시를 찾아보세요! 익스프레스 예약을 운영하고 있는 매장이에요." />
          </li>
          <li>
            <img src={Step02} alt="예약 방법 2 - 캘린더에서 익스프레스 예약 기간을 찾아 예약을 진행해 보세요." />
          </li>
          <li>
            <img src={Step03} alt="예약 방법 3 - 익스프레스 예약이 열리지 않았다면 알림을 신청해보세요. 열리면 알려드릴게요!" />
          </li>
        </ol>
      </HowToUse>
      <HowToApply>
        <SectionTitle>신청 방법</SectionTitle>
        <LargeTitle>
          초대코드를 가진 <br />
          한정된 분들만 모실 수 있어요
        </LargeTitle>
        <CheckSection>
          <Desc className="apply-desc">
            <h3>
              <b>초대코드</b>를 받고 싶어요!
            </h3>
            <p>
              가입버튼을 누르시면 초대코드를 신청할 수 있어요.
              <b>대신 선정과정을 거쳐야 해요.</b>
            </p>
          </Desc>
          <ul>
            <AgonizeList>빨리 신청할수록 선정 확률이 높아져요.</AgonizeList>
            <AgonizeList>
              <div>
                예약내역에 <em className="red">노쇼나 취소</em>가 많은 분들은 거절될 확률이 높아요.
              </div>
            </AgonizeList>
          </ul>
        </CheckSection>
        <InvitationDesc>
          선정된 분들께는 문자메시지로 초대코드를 보내드릴게요.
          <strong>초대코드 입력 후 결제를 마치면 완료!</strong>
        </InvitationDesc>
        <Notice>
          <SmallTitle>유의사항 안내</SmallTitle>
          <NoticeListWrap>
            <li>익스프레스 예약의 양도 및 매매는 불가합니다. 적발 즉시 멤버십이 정지됩니다.(환불 불가)</li>
            <li>매장당 한 달에 최대 2번까지 예약할 수 있습니다. 예약을 취소해도 횟수는 복원되지 않습니다.</li>
            <li>예약의 양도 및 매매를 방지하는 차원에서 같은 날 동시간대 중복 예약은 불가합니다.</li>
            <li>멤버십 해지 시 결제 금액은 환불되지 않습니다.</li>
            <li>특정 레스토랑만을 위한 가입은 추천하지 않습니다.</li>
            <li>위약금 적용기간 내 예약 취소 3회 초과 시 멤버십 자동 해지됩니다.</li>
            <li>위약금 적용기간 외 예약 취소 10회 초과 시 멤버십 자동 해지됩니다.</li>
            <li>노쇼의 경우 조건 없이 멤버십 해지됩니다.</li>
            <li>위 규정 위반 시 재가입은 불가합니다.</li>
            <li>예약 시간 기준 30분 이상 지각 혹은 위반시에는 향후 예약에 대해 불이익을 받을 수 있습니다.</li>
          </NoticeListWrap>
        </Notice>
      </HowToApply>
      */}
      {membershipGrade === '02' ? null : (
        <ButtonWrapper>
          <Button classKey="premium" disabled={false} colorLevel="primary" type="sticky" onClick={() => setAlertModal({ visible: true, key: yolkInvitationCodeAlert })}>
            멤버십 가입 신청
          </Button>
        </ButtonWrapper>
      )}

      <ModalPortal>
        <Modal.Alert key={yolkInvitationCodeAlert} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">욜크 멤버십은 초대코드를 발급받고 결제한 후 이용하실 수 있어요. 초대코드 발급을 신청하시겠어요?</p>
          </section>
          <section className="button-wrapper">
            <button className="white" onClick={() => setAlertModal({ visible: false, key: yolkInvitationCodeAlert })}>
              아니요
            </button>
            <button onClick={applyJoinMembership}>초대코드 신청</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </Fragment>
  );
};

export default Entry;
