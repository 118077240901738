import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const MainMallContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 15px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.4), 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  & > a {
    .mall-img-wrapper {
      /* shadow-product */
      height: 343px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .blur-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        overflow: hidden;
        background: rgba(59, 59, 57, 0.4);
      }

      .blur-background {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 333.33%; /* 100% / 30% to cover the entire image */
        background-image: inherit;
        background-position: center;
        background-size: cover;
        backdrop-filter: blur(20px);
      }
      .overlay-contents {
        position: inherit;
        color: white;
        /* text-align: center; */
        padding: 28px 20px;
        z-index: 1;
        width: 100%;

        h2 {
          margin: 0 0 20px;
          font-size: 36px;
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
        }
        p {
          margin: 0;
          font-size: 24px;
          font-weight: 100;
        }
      }
    }
    & > h2 {
      max-width: 100%;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      ${Typography.body_28_sb}
    }
    & > span {
      display: block;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      ${Typography.small_1}
      color:${Colors.gray6}
    }
  }
`;
export const MainMallFullBadge = styled.div<React.CSSProperties>`
  &::after {
    content: 'FULL';
  }
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 2px 12px;
  border-radius: 12px;
  background: ${Colors.gray11};
  color: ${Colors.white};
  ${Typography.small_3};
`;

export const MainMallComingSoonBadge = styled.div<React.CSSProperties>`
  &::after {
    content: 'COMING SOON';
  }
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 2px 12px;
  border-radius: 12px;
  background: rgba(232, 255, 104, 1);
  color: rgba(20, 18, 14, 1);
  ${Typography.small_3};
  font-weight: 300;
`;

export const MainMallNewBadge = styled.div<React.CSSProperties>`
  &::after {
    content: 'COMING\\ASOON';
    white-space: pre-wrap;
    text-align: center;
  }
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 12px;
  border-radius: 12px;
  color: ${Colors.white};
  ${Typography.body_28_m};
  width: 100%;
  height: 100%;
  background: rgba(39, 42, 45, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;
