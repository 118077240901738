import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles/typography';

export const UserInfo = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 32px;

  padding: 0 32px 96px;

  .info-box {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    ${Typography.body_28_ss}
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      flex: 0 0 auto;
      white-space: pre-wrap;
    }

    .label {
      display: flex;
      width: 120px;
      align-items: flex-start;
      gap: 8px;
      ${Typography.body_28_s}
    }
  }
`;
