import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const AgreeTermsContainer = styled.div<React.CSSProperties>`
  .agreeterms-title {
    margin: 64px 0 40px 0;
    color: #fff;

    /* Title 3 - SemiBold, 16, 22 */
    font-family: pretendard, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    /*
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -0.02em;
    color: ${Colors.gray9};
    */
  }

  .depth-line {
    width: 100%;
    height: 6px;
    opacity: 0.1;
    background: #fff;
    margin-bottom: 50px;
  }

  .edit-bottom-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 60px;
  }
  .edit-bottom-items {
    color: #fff;
    font-family: pretendard, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .edit-bottom-right-item {
    margin-left: auto;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
  }

  .edit-bottom-right-item > div {
    margin-left: 0;
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    opacity: 0.8;
  }

  .logout {
    width: 100%;
    margin-bottom: 40px;
  }
  .withdrawal-button {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 40px;
    ${Typography.small_1}
    color: ${Colors.gray6}
  }
`;

export const AgreeMentToast = styled.div<React.CSSProperties>`
  ${Typography.title_32_sb}
  > div {
    color: ${Colors.white};
  }
`;

export const AgreeTermsItemsWrapper = styled.div<React.CSSProperties>`
  & > .agreeterms-items:last-of-type {
    margin-bottom: min(80px);
  }
  .agreeterms-items {
    display: flex;
    /* min-height: 86px; */
    margin-bottom: 40px;
    label {
      align-items: baseline;
    }
    .agreement-information {
      margin-left: 24px;
      > :first-of-type {
        color: #fff;
        font-feature-settings: 'liga' off, 'clig' off;

        /* Label 2 (underline) - Medium, 14, 20 */
        font-family: pretendard, sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 142.857% */
        /*text-decoration-line: underline;*/
        margin-bottom: 16px;
      }
    }
    .days {
      color: #fff;
      /* Body 3 - Regular, 12, 18 */
      font-family: pretendard, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      opacity: 0.8;
      /*
      ${Typography.small_1}
      color: ${Colors.gray6};
      */
    }

    .agreement-link-item {
      margin-left: auto;
      padding-top: 4px;
    }

    > svg {
      margin: 11px 0 0 4px;
    }
  }

  .arrow-icon {
    padding-top: 2px;
  }
`;
