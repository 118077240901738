import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const AdditionalProfileContainer = styled.section<React.CSSProperties>`
  .contour-line {
    margin-top: 10px;
    width: 100%;
    height: 2px;
    flex-shrink: 0;
    opacity: 0.1;
    background: #fff;
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    ${Typography.body_28};
    color: ${Colors.gray9};

    > svg {
      vertical-align: middle;
      color: #fff !important;
    }
  }
`;

export const AdditionalContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  font-feature-settings: 'liga' off, 'clig' off;

  /* Label 2 - Medium, 14, 20 */
  font-family: pretendard, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  > i {
    min-width: 40px;
    min-height: 40px;

    > svg {
      vertical-align: middle;
      color: #fff;
    }
  }
`;
