import React from 'react';

import dayjs from 'dayjs';

import MainSubtitle from '../MainSubtitle/MainSubtitle';
import { ReservationInfoContainer } from '../ReservationInfo/ReservationInfo.constants';

import { filterShowPartyDetailPeople } from '@/hooks/reservationHistory.hook';
import { AddComma } from '@/shared/libs/functions';

dayjs.locale('ko');

const CompleteReservationInfo = ({ reservation, name }): React.ReactElement => {
  return (
    <ReservationInfoContainer>
      <h3>{name}</h3>
      <MainSubtitle subtitle="예약내용 확인" />
      <div className="info-box">
        <div className="label">예약일시</div>
        <div className="value">{dayjs(reservation.visitAt).format('YYYY년 M월 D일 (ddd) LT')}</div>
      </div>
      <div className="info-box">
        <div className="label">예약인원</div>
        <div className="value">
          {reservation.totalPartySize}명 ({filterShowPartyDetailPeople(reservation.partyDetail)})
        </div>
      </div>
      {reservation.paymentAmount ? (
        <div className="info-box">
          <div className="label">예약금액</div>
          <div className="value">{AddComma(reservation.paymentAmount)}원</div>
        </div>
      ) : null}
    </ReservationInfoContainer>
  );
};

export default CompleteReservationInfo;
