import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const DepositNoticeContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 32px;
  padding: 0 32px;

  /* padding: 64px 32px; */
  .noshow-container {
    display: flex;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    ${Typography.body_28}
    border-radius: 3px;
    border: 1px solid var(--green-300, #e8ff68);
    background: rgba(232, 255, 104, 0.1);

    /* display: flex;
    padding: 32px 24px;
    color: ${Colors.gray8};
    ${Typography.small_1};
    background-color: ${Colors.gray1}; */
    .noshow {
      margin-right: 24px;
      color: ${Colors.red2};
      font-weight: 500;
    }
    .description {
      ${Typography.small_3}
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      & > svg {
        width: 34px;
        height: 34px;
      }
    }
  }
  hr {
    margin: 48px 0px;
    border: solid 1px ${Colors.gray3};
    background: ${Colors.gray3};
  }
`;

export const PaymentInDepositNotice = styled.div`
  padding: 48px 32px 96px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  .noshow-container {
    display: flex;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 6px;
    border: 1px solid var(--green-300, #e8ff68);
    background: rgba(232, 255, 104, 0.1);
    ${Typography.small_3};

    .noshow-description {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      flex: 1 0 0;
      & > svg {
        display: flex;
        width: 24px;
        height: 36px;
        padding: 6px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
      }
    }

    /* .noshow {
      color: ${Colors.red2};
      font-weight: 500;
    } */
  }

  .deposit-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    .title {
      ${Typography.title_34};
    }
  }
`;

export const DepositCategoryWrapper = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${Typography.title_32};

    .money {
      color: ${Colors.green1};
      ${Typography.title_40_s}
    }
  }
`;

export const DepositListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 32px;
  ${Typography.title_31}

  .money {
    color: ${Colors.green1};
    ${Typography.title_40_s}
  }
`;
