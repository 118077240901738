import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button/Button';
import { ButtonWrapper } from '@/components/CardPaymentInfo/CardPaymentInfo.styles';
import ManageMembership from '@/components/ManageMembership/ManageMembership';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import { MemberShipContainer } from '@/shared/styles/pages/MemberShip';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

function MypageMembershipPage(): React.ReactElement {
  const { data: userMembership, isLoading } = useUserMembership();

  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderState({
      title: '멤버십 관리',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <MemberShipContainer>
        <ManageMembership />
      </MemberShipContainer>
      <ButtonWrapper>
        {userMembership.membership.membershipGrade === '01' ? (
          <Button
            colorLevel="secondary"
            type="sticky"
            classKey="auth-button"
            onClick={() => {
              navigate('/membership/entry', { replace: true, state: { prevPath: location.pathname } });
            }}
          >
            결제하고 혜택 받기
          </Button>
        ) : null}
      </ButtonWrapper>
    </>
  );
}

export default MypageMembershipPage;
