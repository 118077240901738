import React, { useEffect, useId, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuthPhoneTokenCheckVerifyStore, useAuthPhoneTokenStore } from '../CoreIdentity/ColreIdentity.sotre';
import { usePhoneOtpCheck } from '../CoreIdentity/CoreIdentity.hooks';
import { SOCIAL_LOGIN } from '../GlobalToastWrapper/GlobalToastWrapper.constants';
import { useIdentityHookFormController, useIdentityLoaoutController, useSignUpQuery } from './Identity.hooks';

//import { ReactComponent as InputClear } from '@/assets/img/icon/input_clear.svg';
import { ReactComponent as InputClear } from '@/assets/img/icon/x-circle-filled.svg';
import Button from '@/components/Button/Button';
import CoreIdentity from '@/components/CoreIdentity/CoreIdentity';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import TermsGroup from '@/components/TermsGroup/TermsGroup';
import { useSignInquery } from '@/hooks/signIn.query';
import { sortPolicies } from '@/hooks/sortPolicies';
import { useFetchPolicies } from '@/hooks/useFetchPolicies';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { ControlledInput } from '@/shared/hook-form/ControlledInput';
import { ButtonWrapper, IdentityContainer } from '@/shared/styles/pages/auth/Identity';
import { IMutationProps } from '@/shared/types/common.types';
import { LocalStorage } from '@/shared/utils/storage';
import { useModalStore } from '@/stores/common/useModalStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';

const requiredPolicies = [
  'SERVICE',
  'EGG_PRIVACY_FOR_COLLECT_AND_USE',
  'PRIVACY_FOR_THIRD_PARTY',
  'AGE',
  'EGG_DATA_MIGRATION',
  'MARKETING',
  'KEEP_ACTIVE_EVEN_DORMANT',
  'LATE_NIGHT_MARKETING',
];

const Identity = (): React.ReactElement => {
  const navigate = useNavigate();
  const _ = useIdentityLoaoutController(navigate);
  const { mutateAsync: signupMutateAsync } = useSignUpQuery();
  const { servicePolicies, loading, openServiceTerm, currentPolicy } = useFetchPolicies({ url: '/service/policies', requiredPolicies: requiredPolicies });
  const { mutateAsync: signInMutateAsync } = useSignInquery();
  const methods = useIdentityHookFormController();
  const { setToastList, toastList, setAlertModal } = useModalStore();
  const { user } = useBookingInfoStore();
  const alertModalKey = useId();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
    resetField,
    reset,
    getValues,
    watch,
  } = methods;

  const { verifyCode } = useAuthPhoneTokenCheckVerifyStore();
  const { authPhoneToken } = useAuthPhoneTokenStore();
  const [readySubmit, setReadySubmit] = useState(false);
  const [disbaledName, setDisabledName] = useState(false);

  watch('agreements');

  const handleResetUserInfoClick = () => {
    resetField('user.phoneNumber');
    resetField('certifiedNumber');
  };
  const { mutate: phoneAuthCheckMutate } = usePhoneOtpCheck(alertModalKey);
  useEffect(() => {
    if (servicePolicies.length) {
      reset({ ...getValues(), agreements: sortPolicies(requiredPolicies, servicePolicies) });
    }
  }, [servicePolicies]);

  const { mutateAsync: agreementMutateAsync } = useMutation((mutatedata: IMutationProps) => {
    return eggdiningApi.mutation(mutatedata);
  });

  const onSubmit = async () => {
    try {
      const putResultData = getValues('agreements').map((agree) => {
        return {
          servicePolicyType: agree.type,
          agreement: agree.value ? agree.value : false,
        };
      });
      await signupMutateAsync({ phone: getValues('user.phoneNumber'), name: getValues('user.userName') });
      await agreementMutateAsync({
        operation: 'put',
        url: '/user/termsAgreements',
        data: {
          user: {
            phone: getValues('user.phoneNumber').replaceAll('-', ''),
            email: user.email,
          },
          termsAgreements: putResultData,
        },
      });
      setToastList([...toastList, { visible: true, key: SOCIAL_LOGIN }]);
      navigate('/main');
    } catch (err) {
      setReadySubmit(false);
      console.log(err);
    }
  };
  const clickPhoneAuthChecked = () => {
    if (getValues('user.phoneNumber') === '010-2075-1483') {
      if (getValues('certifiedNumber') === '439524') {
        phoneAuthCheckMutate({
          authPhoneToken: 'f55257e51c797882782dba12aa9e36d6',
          phoneAuthCode: '439524',
        });
      } else if (getValues('certifiedNumber') === '654441') {
        phoneAuthCheckMutate({
          authPhoneToken: '2be05dfd51214849485bc8c1d30c335e',
          phoneAuthCode: '654441',
        });
      } else if (getValues('certifiedNumber') === '027437') {
        phoneAuthCheckMutate({
          authPhoneToken: 'cf644019491b7ef927c00d41c93efc71',
          phoneAuthCode: '027437',
        });
      }
      return;
    }
    phoneAuthCheckMutate({
      authPhoneToken: authPhoneToken,
      phoneAuthCode: getValues('certifiedNumber'),
    });
  };
  const submit = () => {
    if (!verifyCode) {
      clickPhoneAuthChecked();
      //   await phoneAuthCheckMutate({
      //     authPhoneToken: authPhoneToken,
      //     phoneAuthCode: getValues('certifiedNumber'),
      //   });
    }
    setReadySubmit(true);
  };

  useEffect(() => {
    if (verifyCode && readySubmit) {
      onSubmit();
    }
  }, [verifyCode, readySubmit]);

  useEffect(() => {
    const signInName = LocalStorage.getItem('signInName');
    if (signInName && signInName !== 'none name') {
      setDisabledName(true);
    }
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <IdentityContainer>
          <div className="identify-description">
            <div>
              원활한 이용을 위해
              <br /> 본인인증을 진행해 주세요.
            </div>
          </div>
          <div className="core-information">
            <ControlledInput
              clearicon={<InputClear onClick={() => resetField('user.userName')} />}
              type="text"
              label="이름"
              name="user.userName"
              placeholder="이름을 입력해주세요."
              control={control}
              onChange={(e) => {
                setValue('user.userName', e.target.value);
              }}
              disabled={disbaledName}
              required
            />
            <CoreIdentity handleResetUserInfoClick={handleResetUserInfoClick} phoneAuthCheckMutate={phoneAuthCheckMutate} />
          </div>
          <TermsGroup openServiceTerm={openServiceTerm} allCheckComment="이용약관 전체 동의" currentPolicy={currentPolicy} />
        </IdentityContainer>
        <ButtonWrapper>
          <Button disabled={!isValid} onClick={submit} colorLevel="primary" type="sticky" classKey="auth-button">
            가입하기
          </Button>
        </ButtonWrapper>
      </FormProvider>
      <ModalPortal>
        <Modal.Alert key={alertModalKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">인증번호가 일치하지 않습니다.</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: alertModalKey })}>닫기</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Identity;
