import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EgglogoMain } from '@/assets/img/egg_logo_main.svg';
import { ReactComponent as RightArrowGray } from '@/assets/img/icon/rightArrowGray.svg';
import { SOCIAL_LOGIN } from '@/components/GlobalToastWrapper/GlobalToastWrapper.constants';
import { LocalStorage } from '@/shared/configs/storage';
import { SignInPageContainer } from '@/shared/styles/pages/auth/signin';
import { isApp } from '@/shared/utils';
import { useAppleLoginHook } from '@/shared/utils/useAppleSignin';
import { useGoogleLoginHook } from '@/shared/utils/useGoogleSignin';
import { useKakaoSignin } from '@/shared/utils/useKakaoSignin';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const signin = (): React.ReactElement => {
  const navigate = useNavigate();
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const { handleGoogleLogin } = useGoogleLoginHook();
  const { handleAppleLogin } = useAppleLoginHook();
  const { handleKakaoLogin } = useKakaoSignin();
  const [toggleLoaded, setToggleLoaded] = useState(false);
  const { setMemberId } = useUserProfileStore();
  const { setToastList, toastList } = useModalStore();

  const versionCheck = () => {
    const userAgent = window.navigator.userAgent.toLowerCase().split('/');
    const currentVersion = userAgent[userAgent.length - 1];
    const previousVersion = '2.0.0';
    if (currentVersion > previousVersion) {
      return true;
    }
    return false;
  };

  const handleSocialLogin = async (e) => {
    const { name } = e.target;

    const handleNoneLogin = () => {
      LocalStorage.setItem('loginType', 'none');
      navigate('/main');
    };

    await LocalStorage.setItem('social', name);
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isios = /iphone|ipod|ipad/.test(userAgent);
    const versionValidation = isApp() && versionCheck();

    if (name === 'kakao') {
      if (versionValidation) {
        if (isios) {
          window.webkit.messageHandlers.kakaoLogin.postMessage('');
        } else {
          window.Eggapp.kakaoLogin();
        }
      }
      handleKakaoLogin(versionValidation);
    }
    if (name === 'apple') {
      handleAppleLogin();
    }
    if (name === 'google') {
      if (versionValidation) {
        if (isios) {
          window.webkit.messageHandlers.googleLogin.postMessage('');
        } else {
          window.Eggapp.googleLogin();
        }
      }
      handleGoogleLogin();
    }
    if (name === 'none-login') {
      handleNoneLogin();
    }
  };

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    //setToggleLoaded(true);
    setTimeout(() => {
      setToggleLoaded(true);
    }, 100);
    LocalStorage.removeItem('signInName');
    LocalStorage.removeItem('loginType');
  }, []);

  useEffect(() => {
    const memberId = LocalStorage.getItem('memberId');
    const token = LocalStorage.getItem('jwtToken');

    if (memberId && token) {
      setMemberId(memberId);
      setToastList([...toastList, { visible: true, key: SOCIAL_LOGIN }]);
      navigate('/main');
    }
  }, []);

  return (
    <SignInPageContainer className={toggleLoaded ? 'siginin-loaded' : ''}>
      <div className="siginin-wrapper">
        <div className="siginin-main-bg">
          <div className="siginin-logo-container">
            <h3 className="siginin-logo-title">DiningClub</h3>
            <p className="signin-logo-desc">독보적인 미식 경험, 오직 다이닝클럽에서만.</p>
          </div>
        </div>
      </div>
      <section
        className="signin-button-wrapper"
        onClick={(e) => {
          handleSocialLogin(e);
        }}
      >
        <button className="signin-with-kakao" name="kakao">
          <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 18 18" fill="none">
            <g clip-path="url(#clip0_295_11107)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.00002 0.599976C4.02917 0.599976 0 3.71293 0 7.55226C0 9.94 1.5584 12.0449 3.93152 13.2969L2.93303 16.9445C2.84481 17.2668 3.21341 17.5237 3.49646 17.3369L7.87334 14.4482C8.2427 14.4838 8.61808 14.5046 9.00002 14.5046C13.9705 14.5046 17.9999 11.3918 17.9999 7.55226C17.9999 3.71293 13.9705 0.599976 9.00002 0.599976Z"
                fill="black"
                fill-opacity="0.9"
              />
            </g>
            <defs>
              <clipPath id="clip0_295_11107">
                <rect width="17.9999" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>{' '}
          카카오톡으로 계속
        </button>
        <button className="signin-with-google" name="google">
          Google로 계속
        </button>
        <button className="signin-with-apple" name="apple">
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.6872 5.0637C14.0729 5.84488 13.0413 6.43077 12.2184 6.43077C12.1256 6.43077 12.0329 6.41856 11.975 6.40636C11.9634 6.35753 11.9402 6.21106 11.9402 6.06459C11.9402 5.0637 12.4154 4.08722 12.937 3.46472C13.5977 2.64692 14.6988 2.03662 15.6145 2C15.6377 2.10985 15.6493 2.24412 15.6493 2.37839C15.6493 3.36707 15.2436 4.35575 14.6872 5.0637ZM10.6563 20.8361C10.2432 21.023 9.85176 21.2 9.32064 21.2C8.18473 21.2 7.39655 20.1015 6.49246 18.7588C5.43768 17.172 4.57996 14.7186 4.57996 12.3995C4.57996 8.66446 6.88655 6.68709 9.15837 6.68709C9.82144 6.68709 10.4273 6.94313 10.966 7.1708C11.3973 7.35304 11.7855 7.5171 12.1256 7.5171C12.4206 7.5171 12.7883 7.36411 13.2168 7.18579C13.8153 6.93673 14.5325 6.63827 15.3363 6.63827C15.8463 6.63827 17.7125 6.68709 18.9411 8.5424C18.9341 8.54817 18.919 8.55861 18.8971 8.57383C18.5948 8.78399 16.9822 9.90504 16.9822 12.2042C16.9822 15.0482 19.3352 16.0613 19.4163 16.0857C19.4142 16.0912 19.4094 16.1068 19.4018 16.1317C19.3245 16.3838 18.9557 17.5856 18.1645 18.8076C17.3879 19.9794 16.565 21.1756 15.3363 21.1756C14.7308 21.1756 14.3449 20.9956 13.9465 20.8098C13.5213 20.6116 13.0819 20.4066 12.3459 20.4066C11.6059 20.4066 11.1176 20.6275 10.6563 20.8361Z"
              fill="white"
            />
          </svg>{' '}
          Apple로 계속
        </button>
        <button className="none-login-button" name="none-login">
          로그인 없이 둘러보기&nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 13 12" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.05893 0.558105L4.17505 1.44199L8.73311 6.00005L4.17505 10.5581L5.05893 11.442L10.5009 6.00005L5.05893 0.558105Z"
              fill="white"
            />
          </svg>
        </button>
      </section>
      <div className="siginin-main-bg"></div>
    </SignInPageContainer>
  );
};
//<section className="siginin-main-bg">
//      <div className="signin-top-area">
//      <h3 className="siginin-logo-title">DiningClub</h3>
//      <p className="signin-logo-desc">독보적인 미식 경험, 오직 다이닝클럽에서만.</p>
//     </div>
//    </section>
////   <section
//className="signin-button-wrapper"
//     onClick={(e) => {
//       handleSocialLogin(e);
//     }}
//   >
//     <button className="signin-with-kakao" name="kakao">
//      카카오톡으로 계속하기
//     </button>
//   <button className="signin-with-apple" name="apple">
//      Apple로 계속하기
//   </button>
//   <button className="signin-with-google" name="google">
//   Google로 계속하기
//</button>
// <button className="none-login-button" name="none-login">
// 로그인 없이 둘러보기
//<RightArrowGray className="icon" />
//</button>
//</section>
export default signin;
