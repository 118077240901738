import React from 'react';

import styled from '@emotion/styled';

export const ProfileContainer = styled.section<React.CSSProperties>`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 16px;
  gap: 10px;
  .user-profile-emblem {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 99px;
    margin-right: 8px;
  }
  .normal-grade {
    background: #fff;
  }
  .yolk-grade {
    background: var(--gray-900, #14120e);
  }
  .right-item {
    margin-left: auto;
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    opacity: 0.8;
    display: flex;
    align-items: center;
    flex-direction: row;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }
  .right-item > div {
    margin-left: 0;
  }
  margin-bottom: 28px;
  .user-photo {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-bottom: 28px;
    img {
      width: 160px;
      height: 160px;
    }
  }
  .user-name {
    color: #fff;
    font-family: pretendard, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
  }
`;
