import React from 'react';

import { ReactComponent as CheckCircle } from '@/assets/img/icon/check_circle_filled.svg';
import { CompleteHeaderContainer } from '@/components/CompleteHeader/CompleteHeader.styles';

const CompleteHeader = (): React.ReactElement => {
  return (
    <CompleteHeaderContainer>
      <CheckCircle />
      <div className="status-info-text">예약이 완료되었습니다.</div>
      {/* <div className="mall-name">{name}</div> */}
    </CompleteHeaderContainer>
  );
};

export default CompleteHeader;
