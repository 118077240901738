import React from 'react';

import styled from '@emotion/styled';

export const MembershipContainer = styled.section<React.CSSProperties>`
  width: 100%;
  margin: 30px 0;
  border-radius: 3px;
  border: 1px solid var(--green-300, #e8ff68);
  background: rgba(232, 255, 104, 0.1);
  /*
  padding: 21px 32px;
  margin-bottom: 64px;
  background: #ffffff;
  box-shadow: 0px 4px 16px -4px rgba(39, 42, 45, 0.16);
  border-radius: 8px;*/
  padding: 24px;

  .membership-info {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .white-club {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    background: #fff;
    color: var(--gray-900, #14120e);
  }

  .black-club {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    background: var(--gray-900, #14120e);
  }

  .right-item {
    margin-left: auto;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
  }

  .right-item > div {
    margin-left: 0;
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    opacity: 0.8;
  }

  .black-benefit {
    display: flex;
    padding: 0px 2px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    margin: 26px 0;
  }

  .black-benefit-contents {
    display: flex;
    align-items: center;
    margin-top: 16px;
    color: #fff;
    opacity: 0.8;

    /* Body 3 - Regular, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }

  .btn-upgrade-plan {
    display: flex;
    padding: 18px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 3px;
    background: var(--green-300, #e8ff68);
    color: var(--gray-900, #14120e) !important;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }

  .content {
    display: flex;
    justify-content: space-between;
    .user-name {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      color: #272a2d;
      span {
        margin-left: 8px;
      }
    }
  }
  .membership-status {
    display: flex;
    align-items: center;
    height: 62px;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #272a2d;
    padding: 13px 28px;
    background: #ffffff;
    border: 2px solid #f6c944;
    border-radius: 31px;
  }
`;
