import React from 'react';

import styled from '@emotion/styled';

import Tel from '@/assets/img/icon/tel.svg';
import { AddressMapInfoContainer } from '@/components/Address/Address.styles';
import { Colors, Typography } from '@/shared/styles';

export const HistoryDetailContainer = styled.div<React.CSSProperties>`
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  & > span {
    ${Typography.title_32_sb};
    color: ${Colors.gray5};
    padding-bottom: 8px;

    &.successCancel {
      border-radius: 5px;
      background: rgba(20, 18, 14, 0.8);

      /* effect-overlays */
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15), 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(25px);
      color: #fff;
      padding: 18px 26px;
      margin-bottom: 20px;
    }
  }
  & > h1 {
    ${Typography.title_44};
    /*color: ${Colors.black};*/
    font-family: pretendard, sans-serif;
    color: #fff;
  }
  & > h2 {
    ${Typography.title_36_sb};
    color: #fff;
    padding-top: 100px;
    font-family: pretendard, sans-serif;
  }
  & > ${AddressMapInfoContainer} {
    margin-top: 20px;
    & > a {
      margin-top: 16px;
      ${Typography.body_28};
      font-family: pretendard, sans-serif;
      color: ${Colors.blue};
      padding-left: 35px;
      background-size: 25px 25px;
      background-image: url(${Tel});
      background-repeat: no-repeat;
      background-position: 0;
    }
  }

  .reserve-diff {
    align-self: flex-start;
    opacity: 1;
    border-radius: 6px;
    background: rgba(232, 255, 104, 0.1);
    padding: 10px 12px;
    /*max-width : 60px;*/
    justify-content: center;
    gap: 4px;
    color: var(--green-300, #e8ff68);
    text-align: center;

    /* Body 3 - Regular, 12, 18 */

    font-family: pretendard, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-bottom: 10px;
  }
  .reserve-stts {
    align-self: flex-start;
    opacity: 1;
    border-radius: 6px;
    background: rgba(255, 124, 117, 0.1);
    padding: 10px 12px;
    /*max-width : 60px;*/
    justify-content: center;
    gap: 4px;
    color: var(--red-400, #ff7c75);
    text-align: center;

    /* Body 3 - Regular, 12, 18 */

    font-family: pretendard, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-bottom: 10px;
  }

  .visit-completed {
    align-self: flex-start;
    opacity: 1;
    border-radius: 6px;
    background: var(--gray-90040, rgba(20, 18, 14, 0.4));
    padding: 10px 12px;
    /*max-width : 60px;*/
    justify-content: center;
    gap: 4px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;

    /* Body 3 - Regular, 12, 18 */

    font-family: pretendard, sans-serif;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }

  .kakao-address {
    margin-top: 40px;
  }
`;

export const ReservationDetailContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 12px;
`;

export const ReservationDetailItem = styled.div<React.CSSProperties>`
  display: flex;
  gap: 40px;
  opacity: 0.8;
  & > span {
    flex-basis: 97px;
    flex-shrink: 0;
    ${Typography.body_28};
    color: #fff;
  }
  & > p {
    ${Typography.body_28};
    color: #fff;
  }
`;
