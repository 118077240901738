import React from 'react';

import styled from '@emotion/styled';

import { CheckBoxContainer } from '@/components/CheckBox/CheckBox.styles';
import { MainMallContainer } from '@/components/MainMall/MainMall.styles';
import { Typography, Colors } from '@/shared/styles';

export const MainMallsContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 16px;
  & > ${CheckBoxContainer} {
    /* position: sticky; */
    top: 96px;
    /* z-index: 5; */
    background: #3b3b39;
    width: 100%;
    flex-shrink: 0;
    padding: 35px 0;
    ${Typography.body_27_s}
    & > label {
      display: flex;
      align-items: center;
      gap: 12px;
      color: ${Colors.white};
    }
    .check-img-wrapper {
      margin: -10px;
    }
  }
  & > ${MainMallContainer} {
    width: calc(50% - 8px);
  }
`;
