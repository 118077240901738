import React from 'react';

import styled from '@emotion/styled';

import { BookingSubtitleContainer } from '@/components/MainSubtitle/MainSubtitle.constants';
import { Colors } from '@/shared/styles';
import { Typography } from '@/shared/styles/typography';

export const TimeSelectorContainer = styled.div<React.CSSProperties>`
  /* display: grid; */
  /* justify-items: start; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 32px 0px 32px 32px;
  background: ${Colors.gray11_opacity40};
  gap: 24px;
  border-radius: 6px;
  color: ${Colors.white} ${Typography.body_28} ${BookingSubtitleContainer} {
    margin-left: 32px;
  }
  .time-slider-container {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .time-box {
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.1);
      display: flex;
      height: 64px;
      padding: 0px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      min-width: 176px;
      ${Typography.body_28_s}

      &.selected {
        border-radius: 6px;
        border: 2px solid #fff;
        /* background: rgba(255, 255, 255, 0.10); */
        /* color: #272a2d; */
        /* border: 2px solid #f6c944; */
        /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #f6c944; */
      }

      &:first-of-type {
        /* margin-left: 32px; */
      }

      &:last-child {
        margin-right: 32px;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const getInfoState = (store) => ({ viewDate: store.viewDate, times: store.times, timeDirty: store.timeDirty, calendarDirty: store.calendarDirty });
export const setInfoState = (store) => ({ setViewDate: store.setViewDate, setTimes: store.setTimes, setTimeDirty: store.setTimeDirty });
