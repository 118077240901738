import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles';

export const PartySizeSelectorContainer = styled.div<React.CSSProperties>`
  margin-top: 48px;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  background: #14120e66;
  align-self: stretch;
  align-items: center;
  border-radius: 6px;

  .people-text {
    ${Typography.body_28}
    line-height: 72px;
  }

  .icon-box {
    display: flex;
    padding: 8px 16px;
    /* justify-content: center; */
    gap: 8px;
    align-items: center;
    background: #14120e66;
    border-radius: 16px;

    .people-value {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      font-weight: 400;
      ${Typography.body_28}
    }

    .icon {
      padding: 12px;
      color: #000000;

      &.disabled {
        opacity: 20%;
      }
    }
  }
`;
