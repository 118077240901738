import styled from '@emotion/styled';

import CircleCheck from '@/assets/img/membership/circle_check.svg';
import CircleCheckReverse from '@/assets/img/membership/circle_check_reverse.svg';
import { Colors, Typography } from '@/shared/styles';

export const LargeTitle = styled.h2`
  align-items: center;
  text-align: center;
  ${Typography.title_44}
  color: ${Colors.gray9};

  > span {
    ${Typography.small_1};
    color: ${Colors.gray6};
    display: block;
    padding-top: 28px;
  }
`;

export const SmallTitle = styled.h4`
  ${Typography.title_32_b};
  color: ${Colors.gray9};
  text-align: center;

  .yellow {
    color: ${Colors.yellow3};
  }
`;

export const SectionTitle = styled.strong`
  display: inline-block;
  min-width: 120px;
  margin: 0 auto 28px;
  padding: 3px 16px 5px;
  background: ${Colors.gray9};
  border-radius: 50px;
  ${Typography.small_m};
  color: ${Colors.white};
`;

export const MembershipHeader = styled.header`
  width: 100%;
  text-align: center;
  h4 {
    margin: 70px 0 20px 0;
    color: #fff;
    text-align: center;
    font-family: pretendard, sans-serif;
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 36px */
    letter-spacing: -0.72px;
  }
  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }
  p {
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body 2 - Regular, 14, 20 */
    font-family: pretendard, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    /*
    padding: 20px;
    background: ${Colors.gray0};
    text-align: center;
    ${Typography.body_26};
    color: ${Colors.gray6};

    b {
      ${Typography.body_26_sb};
      color: ${Colors.gray6};
    }
  */
  }
`;

export const DiscountPricesInfo = styled.div`
  text-decoration: line-through;
  color: ${Colors.gray6};
  ${Typography.body_28_b};
  padding-bottom: 16px;

  em {
    padding-left: 8px;
    display: inline-block;
    font-style: normal;
    text-decoration: none;
    color: ${Colors.red2};
  }
`;

export const DescWrapper = styled.article`
  /*
  padding-inline: 32px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  */
  margin: 40px 25px;
  display: flex;
  padding: 40px 120px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--gray-700, #3b3b39);

  /* shadow-product */
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.4), 0px 2px 8px 0px rgba(0, 0, 0, 0.2);

  .black-club {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    font-family: pretendard, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    background: var(--gray-900, #14120e);
  }
  .benefit-price-container {
    margin: 12px 0;

    s {
      color: #fff;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: pretendard, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 142.857% */
      text-decoration-line: strikethrough;
      opacity: 0.8;
    }
    .sale-rate {
      color: var(--green-300, #e8ff68);
      text-align: center;

      /* Title 4 - SemiBold, 14, 20 */
      font-family: pretendard, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }

  h4 {
    color: #fff;
    text-align: center;
    /* Headline 2 - SemiBold, 24, 30 */
    font-family: pretendard, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 55px; /* 125% */
  }
  p {
    opacity: 0.6;
    color: #fff;
    /* Body 3 - Regular, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }

  .introduce-express {
    margin-top: 20px;
    border-radius: 3px;
    border: 1px solid var(--green-300, #e8ff68);
    background: rgba(232, 255, 104, 0.1);
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .introduct-express-title {
      opacity: 1;
      color: #fff;
      /* Title 3 - SemiBold, 16, 22 */
      font-family: pretendard, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
    }

    .introduct-express-body {
      opacity: 1;
      margin-top: 8px;
      color: #fff;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;

      /* Body 2 - Regular, 14, 20 */
      font-family: pretendard, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 30px; /* 142.857% */

      b {
        color: var(--green-300, #e8ff68);
        font-weight: 500;
      }
    }
  }
`;

export const Desc = styled.section`
  width: 100%;
  padding: 40px 32px;

  &.apply-desc {
    margin-bottom: -40px;
  }

  h3 {
    padding-bottom: 28px;
    text-align: center;
    border-bottom: 2px solid ${Colors.gray1};
    ${Typography.title_32_sb}
    @media (min-width: 640px) and (max-width:719px) {
      font-size: 30px;
    }

    b {
      color: ${Colors.yellow3};
    }
  }

  p {
    padding-top: 28px;
    text-align: center;
    ${Typography.body_28}
    color: ${Colors.gray8};

    @media (min-width: 640px) and (max-width: 719px) {
      font-size: 26px;
    }
    b {
      display: block;
      padding-top: 4px;
      font-weight: 600;
    }
  }
`;

export const BorderLine = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background: ${Colors.white};
  margin-top: 40px;
  border: 4px solid ${Colors.yellow1};
  border-radius: 20px;
  min-height: 256px;

  &::after {
    content: url(${CircleCheckReverse});
    position: absolute;
    bottom: calc(-48px / 2);
    width: 48px;
    height: 48px;
    display: block;
  }
`;

export const HowToUse = styled.article`
  margin : 40px 25px;
  display: flex;
  padding: 40px 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: var(--gray-700, #3B3B39);
  /*
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 32px;
  padding-top: 64px;
  */

  h4 { 
    color: #FFF;
    text-align: center;
    /* Headline 2 - SemiBold, 24, 30 */
    font-family: pretendard, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 55px; /* 125% */
    margin : 12px 0 6px 0;
  }

  .b-line {
    width : 100%;

  }

  .exclusive-title { 
    color: var(--green-300, #E8FF68);
    text-align: center;

    /* Title 4 - SemiBold, 14, 20 */
    font-family: pretendard, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }

  p {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body 2 - Regular, 14, 20 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px; /* 142.857% */
  }

  ol {
    li {
      color: #FFF;
      font-feature-settings: 'liga' off, 'clig' off;
      &:last-of-type {
        margin-top: 16px;
      }

      /* Body 2 - Regular, 14, 20 */
      font-family: pretendard, sans-serif;
      font-size: 23px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 142.857% */
    }
  }

  /*
  ol {
    padding-top: 80px;

    li {
      padding-bottom: 100px;

      &:last-of-type {
        padding-bottom: 80px;
      }

      img {
        max-width: 100%;
      }
    }
      */
  }
`;

export const HowToApply = styled.article`
  display: flex;
  flex-direction: column;
  padding: 20px 32px;
  background: var(--gray-700, #3b3b39);

  h4 {
    color: #fff;
    /* Title 4 - SemiBold, 14, 20 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
`;

export const CheckSection = styled.section`
  width: 100%;
  margin-block: 40px;
  background: ${Colors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  ul {
    @media (min-width: 640px) and (max-width: 719px) {
      padding: 40px 52px;
    }
    padding: 40px 72px;
  }
`;

export const InvitationDesc = styled.div`
  ${Typography.body_28};
  @media (min-width: 640px) and (max-width: 719px) {
    ${Typography.body_26}
  }
  text-align: center;
  padding-bottom: 80px;

  strong {
    ${Typography.title_32_b};
    @media (min-width: 640px) and (max-width: 719px) {
      font-size: 30px;
    }
    display: block;
    padding-top: 12px;
  }
`;

export const Notice = styled.section`
  /*border-top: 2px solid ${Colors.gray2};
  padding-top: 40px;*/
`;

export const NoticeListWrap = styled.ul`
  padding-top: 40px;

  li {
    display: flex;
    color: #fff;
    /* Body 3 - Regular, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 150% */
    /*
    ${Typography.small_1};
    color: ${Colors.gray7};
    */

    &:not(&:first-of-type) {
      padding-top: 16px;
    }

    &:before {
      content: '•';
      display: block;
      margin-right: 8px;
    }
  }
`;

export const AgonizeList = styled.li`
  display: flex;
  align-items: center;
  ${Typography.body_28};
  @media (min-width: 640px) and (max-width: 719px) {
    font-size: 26px;
  }
  color: ${Colors.gray9};

  .red {
    color: ${Colors.red2};
    font-style: normal;
    font-weight: 600;
  }

  div b {
    color: ${Colors.blue};
    font-weight: 600;
  }

  &::before {
    content: url(${CircleCheck});
    width: 48px;
    height: 48px;
    display: inline-block;
    margin-right: 20px;
  }

  &:not(&:first-of-type) {
    margin-top: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  /*
  order: 1;
  .primary.sticky {
    ${Typography.title_44}
    height: 156px;
  }
  */
`;
