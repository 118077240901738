import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const BookingInfoContainer = styled.section<React.CSSProperties>`
  display: flex;
  height: 110px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin: 40px 32px 0px 32px;
  .mall-name {
    /* margin-bottom: 8px; */
    ${Typography.title_48}
  }
  .info-text {
    ${Typography.small_3};
    opacity: 0.8;
  }
`;

export const BigSizeBookingInfoTimer = styled.div<React.CSSProperties>`
  display: flex;
  width: 100%;
  padding: 24px 40px;
  align-items: flex-start;
  gap: 28px;
  background: rgba(20, 18, 14, 0.2);
  /* 큰 타이머 */
  /* 
  background-color: ${Colors.red0};
  border-radius: 8px;
  padding: 28px 60px 28px 48px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;
  @media (min-width: 640px) and (max-width: 719px) {
    padding: 28px 32px;
  } */
  .time {
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: rgba(232, 255, 104, 0.1);
    ${Typography.small_3};
    color: ${Colors.green1};
    min-width: 92px;

    /* color: ${Colors.red1};
    font-weight: 500;
    ${Typography.title_32_m} */
  }

  .text {
    color: ${Colors.white};
    ${Typography.small_3}
  }
  .small-size {
    background-color: red;
  }
`;

export const SmallSizeBookingInfoTimer = styled.div<React.CSSProperties>`
  /* small 타이머 */
  position: sticky;
  top: 96px;
  z-index: 999;

  background-color: ${Colors.red0};
  padding: 16px 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @media (min-width: 640px) and (max-width: 719px) {
    padding: 28px 32px;
  }
  .time {
    color: ${Colors.red1};
    font-weight: 500;
    ${Typography.body_26_m}
  }

  .text {
    color: ${Colors.red1};
    ${Typography.body_26}
  }
  .small-size {
    background-color: red;
  }
`;

export const TimerInterserction = styled.div<React.CSSProperties>`
  /* margin-top: -120px; */
  /* margin-bottom: 220px; */
`;
