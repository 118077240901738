import React, { useEffect, useId, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../Button/Button';
import CheckBox from '../CheckBox/CheckBox';
import { CONTINUE_KEY, SUCCESSES_WITHDRAWAL } from '../GlobalToastWrapper/GlobalToastWrapper.constants';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import { useMemberWithdrawalQuery } from './Withdrawal.hooks';

import { ReactComponent as CancelSuccessImg } from '@/assets/img/membership/img_membership_cancel-success.svg';
import { WithdrawalContainer } from '@/components/Withdrawal/Withdrawal.styles';
import { useReservationHistoryData } from '@/hooks/reservationHistory.hook';
import { LocalStorage } from '@/shared/configs/storage';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const Withdrawal = (): React.ReactElement => {
  const navigate = useNavigate();
  const agreementAlertKey = useId();
  const yolkSubscribeAlertKey = useId();
  const reservationAlertKey = useId();

  const { data: reservationHistoryData } = useReservationHistoryData();
  const { mutateAsync } = useMemberWithdrawalQuery();

  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const { setToastList, toastList, setAlertModal } = useModalStore();

  const [checkedWithdrawal, setCheckedWithdrawal] = useState(false);

  const handleLogout = () => {
    LocalStorage.removeItem('memberId');
    LocalStorage.removeItem('jwtToken');
    LocalStorage.removeItem('provider');
    navigate('/auth/signin');
  };

  const checkAlertError = (errorMessages) => {
    switch (errorMessages) {
      case 'EGG_RESERVATION_CHECK':
        // 예약이 남아 있음
        setAlertModal({ visible: true, key: reservationAlertKey });
        break;
      case 'THEYEYAK_RESERVATION_CHECK':
        //더예약 예약이 남아있음
        setAlertModal({ visible: true, key: reservationAlertKey });
        break;
      case 'EGG_PAYMENT_CHECK':
        //에그 정기결제 체크
        setAlertModal({ visible: true, key: yolkSubscribeAlertKey });
        break;
      case 'BAD_REQUEST':
        //유효하지 않은 request
        alert('유효하지 않은 요청입니다.');
        break;
      case 'DATABASE_ERROR':
        //내부 Database 처리 오류
        alert('에러가 발생 했습니다.');
        break;
      default:
    }
  };

  const clickApproveWithdrawal = async () => {
    try {
      if (!checkedWithdrawal) {
        setAlertModal({ visible: true, key: agreementAlertKey });
      } else if (reservationHistoryData.list.find((item) => item.reservation.state === 0)) {
        setAlertModal({ visible: true, key: reservationAlertKey });
        // setToastList([...toastList, { visible: true, key: CONTINUE_KEY }]);
      } else {
        const code = await mutateAsync();
        setToastList([...toastList, { visible: true, key: SUCCESSES_WITHDRAWAL }]);
        handleLogout();
      }
    } catch (err: any) {
      checkAlertError(err.response.data.response.code);
      console.log(err);
    }
  };

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: '회원 탈퇴',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
  }, []);

  return (
    <>
      <WithdrawalContainer>
        <div className="withdrawal-header">
          <div className="title">정말 탈퇴 하시겠습니까?</div>
          <div className="description">
            회원 탈퇴 시 삭제된 회원님의 정보는
            <br />
            복구되지 않습니다.
          </div>
        </div>
        <div className="depth-line"></div>
        {/*
        <div className="withdrawal-header">
          <CancelSuccessImg />
          <div className="title">정말 탈퇴 하시겠어요?</div>
          <div className="description">
            회원 탈퇴 시 삭제된 회원님의 정보는
            <br />
            복구되지 않습니다.
          </div>
        </div>
        */}
        <div className="withdrawal-instructions">
          <div className="title">탈퇴 시 유의시항</div>
          <div className="content-wrapper">
            <div>
              <span>1.</span>
              <div>회원 탈퇴 시, 회원 정보는 즉시 삭제되나 부정 이용거래 방지 및 전자상거래법 등 관련 법령에 따라 보관이 필요한 경우 해당 기간동안 회원정보가 보관됩니다.</div>
            </div>
            <div>
              <span>2.</span>
              <div>회원 탈퇴 시 욜크 멤버십 잔여 이용기간이 남아 있어도 멤버십 혜택을 이용하실 수 없습니다.</div>
            </div>
            <div>
              <span>3.</span>
              <div>욜크 멤버십 환불 신청은 탈퇴 전 진행하시길 바랍니다. 회원 탈퇴 후에는 열람 가능한 정보가 없어 환불이 어려울 수 있습니다.</div>
            </div>
          </div>
          <div className="checkbox-wrapper">
            <CheckBox className="allchecked-items" checked={checkedWithdrawal} onChange={() => setCheckedWithdrawal(!checkedWithdrawal)}>
              <div className="checkbox-coment">위 내용을 모두 숙지하였으며, 이에 동의합니다.</div>
            </CheckBox>
          </div>
        </div>
        <div className="bottom">
          <Button
            onClick={() => {
              navigate('/mypage/edit');
              setToastList([...toastList, { visible: true, key: CONTINUE_KEY }]);
            }}
            colorLevel="primary"
            type="large"
          >
            아니요, 계속 사용하겠습니다.
          </Button>
          <Button onClick={clickApproveWithdrawal} colorLevel="secondary" type="large">
            네, 탈퇴하겠습니다.
          </Button>
        </div>
      </WithdrawalContainer>
      <ModalPortal>
        <Modal.Alert key={agreementAlertKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <section className="desc">
              <div>탈퇴 시 유의사항을 확인하시고 동의해주세요.</div>
            </section>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: agreementAlertKey })}>닫기</button>
          </section>
        </Modal.Alert>
        <Modal.Alert key={yolkSubscribeAlertKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <section className="desc">
              <div>현재 욜크 멤버십 이용 중 입니다. 멤버십 해지 후 탈퇴를 진행해주세요.</div>
            </section>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: yolkSubscribeAlertKey })}>닫기</button>
            <button
              onClick={() => {
                navigate('/mypage');
                navigate('/mypage/membership');
              }}
            >
              멤버십 관리
            </button>
          </section>
        </Modal.Alert>
        <Modal.Alert key={reservationAlertKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <section className="desc">
              <div>현재 방문 예정인 예약이 남아 있습니다. 해당 예약을 취소하시거나 방문하시고 나서 탈퇴를 진행해주세요.</div>
            </section>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: reservationAlertKey })}>닫기</button>
            <button
              onClick={() => {
                navigate('/mypage');
                navigate('/reservation/history');
              }}
            >
              예약내역 확인
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Withdrawal;
