import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

interface IInputContainer {
  error: string;
}
export const InputContainer = styled.div<React.CSSProperties | IInputContainer>`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > label {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    padding-bottom: 8px;
    ${Typography.s_small_300};
    color: ${Colors.white};
    font-feature-settings: 'liga' off, 'clig' off;
    opacity: 0.8;

    & > span {
      color: ${Colors.green1};
    }
  }

  .input-wrapper {
    width: 100%;

    & > div {
      position: relative;

      & > input {
        outline: none;
        width: 100%;
        ${Typography.body_28_s};
        border-radius: 6px;
        background: ${(props: any) => (props.error ? Colors.red400_opacity10 : Colors.white_opacity10)};
        height: 88px;
        padding: 24px;
        /*border: 2px solid ${Colors.gray10};*/
        border: 0;
        &:focus {
          border: 2px solid ${(props: any) => (props.error ? Colors.red400 : Colors.white)};

          & + svg {
            opacity: 1;
          }
        }
        &:disabled {
          opacity: 0.4;
        }
        &::placeholder {
        }
      }
      & > svg {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        right: 28px;
        top: calc(50% - 18px);
      }
    }
  }
  .error-wrapper {
    display: flex;
    justify-content: space-between;
    span {
      display: flex;
      gap: 8px;
      flex-direction: row;
      align-items: center;
      padding-top: 8px;
      ${Typography.small_3};
      color: ${Colors.red400};
      opacity: 0.8;
    }
  }

  /*
  & > div > div {
    position: relative;
    & > input {
      ${Typography.body_28}
      display: flex;
      width: 100%;
      height: 92px;
      padding: 28px;
      gap: 10px;
 
      background: ${Colors.white};
      border: 2px solid ${(props: any) => (props.error ? Colors.red2 : Colors.gray4)};
      border-radius: 4px;
      color: ${Colors.gray9};
      background: rgba(255, 255, 255, 0.1);
      border-radius: 3px;
      color: #fff;

      &::placeholder {
        //color: ${Colors.gray4};
      }
      &:focus {
        border: 2px solid ${(props: any) => (props.error ? Colors.red2 : Colors.gray9)};
        outline: none;

        & + svg {
          opacity: 1;
        }
      }
      &:disabled {
        background: rgba(255, 255, 255, 0.1);
        //border: 2px solid ${Colors.gray2};
        &::placeholder {
          //color: ${Colors.gray2};
        }
      }
      &:placeholder-shown {
        & + svg {
          display: none;
        }
      }
    }
    & > svg {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      right: 28px;
      top: calc(50% - 13px);
    }
  }
    */
`;
